import SectionHeading from "../SectionHeading";
import {
  bnbIcon,
  bnbLogo,
  bubbleImg,
  EleMuskImg,
  etherIcon,
} from "../../utils/allImgs";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { TbStarFilled } from "react-icons/tb";

import React, { useState, useEffect } from "react";
import { useModal } from "../../ContextProvider";
import Modal from "../Modal";
import "./HowToBuy.scss";
// import FaqItem from "../FaqItem";
import { dataFaq } from "../../data/data-faq/dataFaq";
import Footer from "../../layouts/Footer";
import { useHistory } from "react-router-dom";

const HowToBuySection = ({ img, dataUp, dataDown }) => {
  const { setIsOpen, isOpen } = useModal();
  const history = useHistory();
  const onGoStakingPageHandler = () => {};

  const FaqComponent = ({ title, index, text, hyperlink }) => {
    return (
      <div className="faq_item" style={{ marginBottom: "10px" }}>
        <div className="faq_eth">
          <img src={etherIcon} width={40} height={40} alt="" />
        </div>
        <div className="faq_bsc">
          <img src={bnbLogo} width={40} height={40} alt="" />
        </div>
        <div className="faq_title">
          {index}. {title}{" "}
        </div>
        <div className="faq_text">
          {text}{" "}
          {hyperlink !== "" && (
            <span
              onClick={() => {
                history.push("/staking");
              }}
              className="hyper_link_text"
            >
              {hyperlink}
            </span>
          )}
        </div>
      </div>
    );
  };

  // Generate Stars
  const [stars, setStars] = useState([]); // State to store generated stars

  useEffect(() => {
    // Generate stars only once when the component mounts
    const generateRandomStars = (numStars) => {
      const starsArray = [];
      for (let i = 0; i < numStars; i++) {
        const randomTop = Math.random() * 100; // Random percentage for top
        const randomLeft = Math.random() * 100; // Random percentage for left
        const randomSize = Math.random() * 1.5 + 0.5; // Random size multiplier

        starsArray.push(
          <TbStarFilled
            key={i}
            style={{
              position: "absolute",
              top: `${randomTop}%`,
              left: `${randomLeft}%`,
              transform: `scale(${randomSize})`,
              color: "white",
            }}
          />
        );
      }
      return starsArray;
    };

    setStars(generateRandomStars(25)); // Generate 25 stars
  }, []); // Empty dependency array ensures this runs only once

  // Move to Top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling
    });
  }, []); // Run only on mount

  return (
    <div className="how_to_buy_container" id="buy">
      <div className="arrow_buttons">
        <div
          className="arrow_btn"
          onClick={() => {
            history.push("/about");
          }}
        >
          <IoIosArrowDropleftCircle size={50} color="gold" />
        </div>

        <div
          className="arrow_btn"
          onClick={() => {
            history.push("/whitepaper");
          }}
        >
          <IoIosArrowDroprightCircle size={50} color="gold" />
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />

      <div className="section how_to_buy_image">
        <a href="#howtobuySection" className="scroll-downs">
          <div className="mousey">
            <div className="scroller"></div>
          </div>
        </a>
      </div>
      {/* <div className="howtobuy_mask"></div> */}
      <div id="howtobuySection" className="section faq_container">
        {/* Stars added before all other content */}
        <div>{stars}</div>{" "}
        <div className="faq_left">
          <FaqComponent
            title="TesCreate Your Wallet"
            index="1"
            text="If you already have a compatible wallet, go to step 2. If not, you can install any crypto wallets including metamask."
          />
          <FaqComponent
            title="Load your wallet with Crypto"
            index="2"
            text="Load ETH onto your wallet. For now the only available pair is $Elump/ETH on Uniswap."
          />
          <FaqComponent
            title="Load your wallet with Crypto"
            index="3"
            text="Go to Uniswap and connect your wallet. Choose the amount of tokens you want to buy and then click on swap button. You can also stake the tokens to maximize your gains on our"
            hyperlink="staking page"
          />
          <div className="how_to_buy_join_text">
            Join the early stage of Elumphant before it reaches Mars!
          </div>
        </div>
        <div className="faq_right">
          <img src={EleMuskImg} alt="EleMuskImg" />
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default HowToBuySection;
