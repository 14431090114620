import React, { useRef } from "react";
import "./Modal.css"; // Modal-specific styling
import { IoMdClose } from "react-icons/io";
import { ComingSoonGif, homeLogoGif } from "../../utils/allImgs";
import { FaLock } from "react-icons/fa";

function Modal({
  onClose,
  isOpen,
  message,
  lockedTimeType,
  withdrawLockedPeriod,
  claimLockedPeriod,
}) {
  const modalRef = useRef(null);

  if (!isOpen) return null;

  const handleOverlayClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  return (
    <div className="overlay fade show" onClick={handleOverlayClick}>
      <div className="modal_body" ref={modalRef}>
        <div
          onClick={onClose}
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            padding: "15px 15px",
            cursor: "pointer",
          }}
        >
          <div className="">
            <IoMdClose color="white" size={30} />
          </div>
        </div>
        <div className="modal_content">
          <span className="text-center modal_title">{message}</span>
          <div
            className="text-center modal_title"
            style={{ color: "gold", paddingTop: "10px" }}
          >
            <span style={{ paddingRight: "10px" }}>
              <FaLock color="gold" />
            </span>
            {lockedTimeType === "withdraw"
              ? withdrawLockedPeriod
              : `Remaining Time Before Unlocking: ${claimLockedPeriod} hours`}
          </div>
          <img src={ComingSoonGif} width={"60%"} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Modal;
