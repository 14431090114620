import React, { useRef } from "react";
import "./ClaimModal.css"; // Modal-specific styling
import { IoMdClose } from "react-icons/io";
import {
  coinbaseIcon,
  ComingSoonGif,
  ethLogo,
  homeLogoGif,
  metamaskIcon,
  walletconnectIcon,
} from "../../utils/allImgs";

function ClaimModal({ onClose, isOpen }) {
  const modalRef = useRef(null);

  if (!isOpen) return null;

  const handleOverlayClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };
  return (
    <div className="overlay fade show" onClick={handleOverlayClick}>
      <div className="Claim_modal_body" ref={modalRef}>
        <div
          onClick={onClose}
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            padding: "15px 15px",
            cursor: "pointer",
          }}
        >
          <div className="">
            <IoMdClose color="white" size={20} />
          </div>
        </div>
        <div className="claim_modal_content">
          <h5 style={{ color: "white" }} className="claim_modal_title">
            You can claim your tokens after presale ends
          </h5>
          <h5 style={{ color: "gold" }} className="claim_modal_title">
            Presale End Date: Feb 28 2025
          </h5>
          <img src={ComingSoonGif} alt="" />
        </div>
      </div>
    </div>
  );
}
export default ClaimModal;
