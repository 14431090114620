import React, { useRef } from "react";
import "./AlertModal.css"; // Modal-specific styling
import { IoMdClose } from "react-icons/io";
import { astroImg, ComingSoonGif, homeLogoGif } from "../../utils/allImgs";
import { FaLock } from "react-icons/fa";
import { TbAlertSquareRounded } from "react-icons/tb";

function AlertModal({
  onClose,
  isOpen,
  message,
  lockedTimeType,
  withdrawLockedPeriod,
  claimLockedPeriod,
}) {
  const modalRef = useRef(null);

  if (!isOpen) return null;

  const handleOverlayClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  return (
    <div className="overlay fade show" onClick={handleOverlayClick}>
      <div className="alert_modal_body" ref={modalRef}>
        <div
          onClick={onClose}
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            padding: "15px 15px",
            cursor: "pointer",
          }}
        >
          <div className="">
            <IoMdClose color="white" size={30} />
          </div>
        </div>
        <div className="alert_modal_content">
          <span className="text-center modal_title">{message}</span>
          <div
            className="text-center modal_title"
            style={{
              color: "white",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {/* <div>
              <TbAlertSquareRounded size={36} color="white" />
            </div> */}
            Please be advised that Elumphant is not available on Pump.fun or
            Solana. It is exclusively on Ethereum and BSC. Any Elumphant tokens
            on other chains are scams. Please purchase $Elump only through
            Elumphant's official website (Elumphant.com).
          </div>
          <img src={astroImg} width={"60%"} alt="" />
        </div>
      </div>
    </div>
  );
}

export default AlertModal;
