import Marquee from "react-fast-marquee";
import React, { useState, useEffect } from "react";
import { aboutCardData } from "../../data/data-about/data-aboutCard";
import { TbStarFilled } from "react-icons/tb";
import { aboutLogoGif } from "../../utils/allImgs";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import AboutCard from "../AboutCard";
import Footer from "../../layouts/Footer";
import "./HowToStart.scss";
import { useHistory } from "react-router-dom";
const HowToStart = ({ setIsOpen, isOpen }) => {
  const history = useHistory();

  // Generate Stars
  const [stars, setStars] = useState([]); // State to store generated stars

  useEffect(() => {
    // Generate stars only once when the component mounts
    const generateRandomStars = (numStars) => {
      const starsArray = [];
      for (let i = 0; i < numStars; i++) {
        const randomTop = Math.random() * 100; // Random percentage for top
        const randomLeft = Math.random() * 100; // Random percentage for left
        const randomSize = Math.random() * 1.5 + 0.5; // Random size multiplier

        starsArray.push(
          <TbStarFilled
            key={i}
            style={{
              position: "absolute",
              top: `${randomTop}%`,
              left: `${randomLeft}%`,
              transform: `scale(${randomSize})`,
              color: "white",
            }}
          />
        );
      }
      return starsArray;
    };

    setStars(generateRandomStars(25)); // Generate 25 stars
  }, []); // Empty dependency array ensures this runs only once
  // Move to Top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling
    });
  }, []); // Run only on mount
  return (
    <section className="about_section" id="about">
      <div className="about_image">
        <a href="#aboutSection" className="scroll-downs">
          <div className="mousey">
            <div className="scroller"></div>
          </div>
        </a>
      </div>
      <div className="arrow_buttons">
        <div
          className="arrow_btn"
          onClick={() => {
            history.push("/");
          }}
        >
          <IoIosArrowDropleftCircle size={50} color="gold" />
        </div>

        <div
          className="arrow_btn"
          onClick={() => {
            history.push("/how-to-buy");
          }}
        >
          <IoIosArrowDroprightCircle size={50} color="gold" />
        </div>
      </div>

      <div id="aboutSection" className="section roars_about funny_font">
        {/* <div className="about_mask"></div> */}
        <img src={aboutLogoGif} width={240} height={240} alt="" />
        <div className="about_title">The Meme-Coin That Roars!</div>
        <div className="container_about">
          {stars}
          <div className="row">
            {aboutCardData.map((item, id) => (
              <AboutCard
                key={id}
                img={item.img}
                title={item?.title}
                index={item.index}
                content={item.content}
              />
            ))}
          </div>
        </div>
        <div className="row">
          <div></div>
        </div>
      </div>
      <div className="section">
        <Footer />
      </div>
    </section>
  );
};

export default HowToStart;
