import React, { useRef, useState, useEffect } from "react";
import "./StakingModal.css"; // Modal-specific styling
import { IoMdClose } from "react-icons/io";
import { bnbIcon, etherIcon, MoneyphantImg } from "../../utils/allImgs";
import { ethers } from "ethers";
import { useAppKitNetwork } from "@reown/appkit/react";
import MoonLoader from "react-spinners/MoonLoader";

function StakingModal({
  onClose,
  isOpen,
  switchETHLoading,
  switchBSCLoading,
  setStakingAmount,
  stakingAmount,
  onStakingHandler,
  loading,
  setLoading,
  validation,
  stakableAmount,
  setStakingAmountToParsedWei,
  setStakingRealAmount,
  setSelectedNetwork,
  selectedNetwork,
  stakableETHAmount,
  stakableBSCAmount,
  formatNumber,
  setNetworkETHHandler,
  setNetworkBSCHandler,
  stakingSuccess,
  setStakingError,
  stakingError,
}) {
  const { switchNetwork, chainId } = useAppKitNetwork();

  const [txStatus, setTxStatus] = useState("");
  const modalRef = useRef(null);
  if (!isOpen) return null;

  const handleOverlayClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  const onChangeHandler = async (e) => {
    const value = e.target.value;
    setStakingError(false);

    if (value.trim() === "" || isNaN(Number(value))) {
      // Handle empty or invalid input
      console.log("Invalid input. Cannot parse to Wei.");
      setStakingAmount(value); // Set the empty or invalid value
      return;
    }

    try {
      setStakingRealAmount(value);
      console.log("Type >>>", typeof value);
      const parsedWei = await ethers.parseEther(value); // Convert ETH to Wei
      setStakingAmountToParsedWei(parsedWei);
      setStakingAmount(value);
    } catch (error) {
      console.error("Error parsing to Wei:", error.message);
      setStakingAmountToParsedWei(0n);
    }
  };
  // Max Handler
  const maxHandler = () => {
    const maxValue =
      selectedNetwork === 1 ? stakableETHAmount : stakableBSCAmount;
    setStakingAmount(maxValue);
  };
  return (
    <div className="overlay fade show" onClick={handleOverlayClick}>
      <div className="staking_modal_body" ref={modalRef}>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            padding: "15px 15px",
          }}
        >
          <div className="" style={{ cursor: "pointer" }} onClick={onClose}>
            <IoMdClose color="white" size={30} />
          </div>
        </div>
        <div className="staking_modal_content">
          <div className="text-center">
            {stakingError === true && (
              <div className="stake_failed_text">Failed, Please try again</div>
            )}
          </div>

          <div className="staking_modal_network">
            <div
              onClick={
                chainId !== 1 && switchBSCLoading === false
                  ? setNetworkETHHandler
                  : undefined
              }
              className={
                selectedNetwork === 1
                  ? "staking_modal_network_item active_network"
                  : "staking_modal_network_item"
              }
            >
              {switchETHLoading === false ? (
                <div className="switch_bsc_button">
                  <img src={etherIcon} width={16} height={16} alt="" />
                  <div className="staking_modal_network_item_name">ETH</div>
                </div>
              ) : (
                <div className="loading_spinner" style={{ zIndex: 999 }}>
                  <MoonLoader
                    color="gold" // Color of the spinner
                    size={20}
                    loading={switchETHLoading} // Boolean to show/hide loader
                    height={5} // Height of each bar
                    width={5} // Width of each bar
                    radius={1} // Radius of bar edges
                    speedMultiplier={0.5} // Adjust the speed of the animation
                  />
                </div>
              )}
            </div>
            <div
              onClick={
                chainId !== 56 && switchETHLoading === false
                  ? setNetworkBSCHandler
                  : undefined
              }
              className={
                selectedNetwork === 2
                  ? "staking_modal_network_item active_network"
                  : "staking_modal_network_item"
              }
            >
              {switchBSCLoading === false ? (
                <div className="switch_bsc_button">
                  <img
                    style={{ marginLeft: "-5px" }}
                    src={bnbIcon}
                    width={40}
                    height={40}
                    alt=""
                  />
                  <div
                    className="staking_modal_network_item_name"
                    style={{ paddingLeft: "3px" }}
                  >
                    BSC
                  </div>
                </div>
              ) : (
                <div className="loading_spinner" style={{ zIndex: 999 }}>
                  <MoonLoader
                    color="gold" // Color of the spinner
                    size={20}
                    loading={switchBSCLoading} // Boolean to show/hide loader
                    height={5} // Height of each bar
                    width={5} // Width of each bar
                    radius={1} // Radius of bar edges
                    speedMultiplier={0.5} // Adjust the speed of the animation
                  />
                </div>
              )}
            </div>
          </div>
          <div
            style={{ color: "white", paddingBottom: "10px", fontSize: "14px" }}
          >
            <div>
              {" Your Staked Amount: "}
              {selectedNetwork === 1
                ? formatNumber(stakableETHAmount)
                : formatNumber(stakableBSCAmount)}{" "}
            </div>
          </div>
          <div
            className="staking_modal_amount_text"
            style={{ color: "white", fontSize: "14px" }}
          >
            <div>Staking Amount</div>
            {/* <div
              style={{ color: "gold", fontSize: "14px", cursor: "pointer" }}
              onClick={maxHandler}
            >
              Max
            </div> */}
          </div>
          <div className="staking_modal_input">
            <input
              type="number"
              className="hide-spinner"
              value={stakingAmount}
              onChange={onChangeHandler}
              placeholder="0"
              required
            />
          </div>
          <div className="validation_text">{validation}</div>
          <div
            style={{ width: "80%", marginTop: "10px", marginBottom: "10px" }}
            className={
              validation !== "" || loading === true
                ? "btn btn-disabled "
                : "btn btn-warning"
            }
            onClick={onStakingHandler}
          >
            <span>
              {loading === false ? (
                "Stake"
              ) : (
                <div className="loading_spinner">
                  <MoonLoader
                    color="white" // Color of the spinner
                    size={24}
                    loading={loading} // Boolean to show/hide loader
                    height={5} // Height of each bar
                    width={5} // Width of each bar
                    radius={1} // Radius of bar edges
                    speedMultiplier={0.5} // Adjust the speed of the animation
                  />
                </div>
              )}
            </span>
          </div>
          <img src={MoneyphantImg} width={"60%"} alt="" />
        </div>
      </div>
    </div>
  );
}

export default StakingModal;
