import React from "react";
import HowToStart from "../../components/HowToStart";
const About = () => {
  return (
    <div>
      <HowToStart />
    </div>
  );
};

export default About;
