import "./Wellcome.scss";

import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  ethLogo,
  bnbLogo,
  solanaLogo,
  ethUsdtLogo,
  elephantLogo,
  homeLogoGif,
  etherIcon,
  RedCrownIcon,
  bscUsdtLogo,
} from "../../utils/allImgs";
import {
  IoIosArrowDroprightCircle,
  IoIosArrowDropleftCircle,
} from "react-icons/io";
import Modal from "../Modal";
import BuyModal from "../BuyModal";
import MoonLoader from "react-spinners/MoonLoader";
import { LuCopy } from "react-icons/lu";
import {
  useAppKitAccount,
  // useWalletInfo,
  useAppKitProvider,
} from "@reown/appkit/react";
import { TbStarFilled } from "react-icons/tb";

import axios from "axios";

import { ethers } from "ethers";
import {
  useDisconnect,
  useAppKit,
  useAppKitNetwork,
  useWalletInfo,
  useAppKitState,
} from "@reown/appkit/react";
import { NotificationManager } from "react-notifications";

import ElumphantPresaleABI from "../../abi/ElumphantPresale.json";
import USDT_ABI_ETH from "../../abi/USDT_ETH.json";
import USDT_ABI_BSC from "../../abi/USDT_BSC.json";

import { networks, metadata, ethersAdapter } from "../../config";
import ClaimModal from "../ClaimModal";
import TextSlider from "../TextSlider";
import { useModal } from "../../ContextProvider";
import Timer from "../Timer";
import Footer from "../../layouts/Footer";
import { useHistory } from "react-router-dom";
import AlertModal from "../AlertModal";

const SecHeroSection = ({
  apiKey,
  ethContractAddress,
  bscContractAddress,
  bscUsdtContractAddress,
  ethUsdtContractAddress,
  buyAmount,
}) => {
  const {
    isOpen,
    setIsOpen,
    setIsConnectedStatus,
    isConnectedStatus,
    setTotalPurchasedAmount,
    totalPurchasedAmount,
    setPreventAlertModal,
    preventAlertModal,
  } = useModal();
  const history = useHistory();
  const decimals = 18n;
  const divisor = 10n ** decimals; // 10^18
  // Live
  const RPC_URL_ETH = `https://eth-mainnet.g.alchemy.com/v2/${apiKey}`;
  const RPC_URL_BSC = `https://bnb-mainnet.g.alchemy.com/v2/${apiKey}`;
  // Test
  // const RPC_URL_ETH = `https://eth-sepolia.g.alchemy.com/v2/${apiKey}`;
  // const RPC_URL_BSC = `https://bnb-testnet.g.alchemy.com/v2/${apiKey}`;

  const [alertModalOpen, setAlertModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [stakingLoading, setStakingLoading] = useState(false);

  const [buyStatus, setBuyStatus] = useState(false);

  const [selectedNetwork, setSelectedNetwork] = useState(1);
  const [selectedCoin, setSelectedCoin] = useState(false);
  const [elumReceiveAmount, setElumReceiveAmount] = useState("");
  const [stakingAmount, setStakingAmount] = useState("");
  const [stakingType, setStakingType] = useState("");

  // States for catching events
  const [buyEvent, setBuyEvent] = useState(false);
  const [changeElumpPriceEvent, setChangeElumpPriceEvent] = useState(false);

  const [period, setPeriod] = useState(false);
  // Memoize the setPeriod function
  const memoizedSetPeriod = useCallback((value) => {
    setPeriod(value);
  }, []);

  const { walletProvider } = useAppKitProvider("eip155");
  const [payAmount, setPayAmount] = useState("");
  const [purchasedAmount, setPurchasedAmount] = useState(0.0);
  const [elumPrice, setElumPrice] = useState(0);
  const [myBalance, setMyBalance] = useState(0);
  const [failedMessage, setFailedMessage] = useState("");
  // Native_ERC20

  const [progressBarStatus, setProgressBarStatus] = useState(0);
  const [inputError, setInputError] = useState("");
  const { address, caipAddress, isConnected, status } = useAppKitAccount();

  // const walletInfo = useWalletInfo();
  const { disconnect } = useDisconnect();
  const { open } = useAppKit();
  const { switchNetwork, chainId } = useAppKitNetwork();
  const [latestETHPrice, setLatestETHPrice] = useState(0);
  const [latestBSCPrice, setLatestBSCPrice] = useState(0);
  const [leftAmount, setLeftAmount] = useState(0);
  const [myStakedAmount, setMyStakedAmount] = useState(0);
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [copiedAddress, setCopiedAddress] = useState(false);
  const [switchETHLoading, setSwitchETHLoading] = useState(false);
  const [switchBSCLoading, setSwitchBSCLoading] = useState(false);

  function minimizeAddress(addr) {
    return addr.substring(0, 6) + "..." + addr.substring(addr.length - 4);
  }
  const formatNumber = (number) => {
    const [integerPart, decimalPart] = number.toString().split(".");
    const formattedInteger = parseInt(integerPart, 10).toLocaleString(); // Format the integer part with commas
    return decimalPart
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  };
  const createNotification = (type, buyer, amount) => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(
          `Address ${buyer} bought ${Number(
            amount / 1000000000000000000n
          )} $Elump!`
        );
        break;
      case "warning":
        NotificationManager.warning(
          "Warning message",
          "Close after 3000ms",
          3000
        );
        break;
      case "error":
        NotificationManager.error("Error message", "Click me!", 5000, () => {
          alert("callback");
        });
        break;
      default:
        break;
    }
  };
  // @@@
  useEffect(() => {
    if (chainId === 1) {
      setSelectedNetwork(1);
    } else if (chainId === 56) {
      setSelectedNetwork(2);
    } else if (chainId === 1) {
      setSelectedNetwork(1);
    } else if (chainId === 56) {
      setSelectedNetwork(2);
    } else {
      // switchNetwork(networks[1]);
      setSelectedNetwork(1);
    }
  }, [chainId]);

  //
  const [lockStatus, setLockStatus] = useState("unknown"); // "locked" or "unlocked"
  const [accounts, setAccounts] = useState([]);

  const checkMetaMaskLockStatus = async () => {
    try {
      if (!window.ethereum) {
        console.warn("MetaMask is not installed.");
        return {
          status: "not_installed",
          message: "MetaMask is not installed.",
        };
      }

      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (accounts.length === 0) {
        return { status: "locked", message: "MetaMask is locked." };
      } else {
        return { status: "unlocked", accounts };
      }
    } catch (error) {
      console.error("Error checking MetaMask lock status: ", error);
      return { status: "error", message: "An error occurred.", error };
    }
  };

  useEffect(() => {
    if (preventAlertModal === false) {
      setAlertModalOpen(true);
    } else {
      setAlertModalOpen(false);
    }
  }, []); // Empty array ensures it only runs on the first render

  useEffect(() => {
    const updateLockStatus = async () => {
      const status = await checkMetaMaskLockStatus();
      setLockStatus(status.status);
      setAccounts(status.accounts || []);
    };

    // Check initial status
    updateLockStatus();

    // Listen for real-time changes
    const handleAccountsChanged = (accounts) => {
      console.log("handleAccountsChanged: ", accounts.length);
      if (accounts.length === 0) {
        setLockStatus("locked");
        setAccounts([]);
      } else {
        setLockStatus("unlocked");
        setAccounts(accounts);
      }
    };

    // Add MetaMask event listeners
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }

    // Cleanup event listeners on unmount
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
      }
    };
  }, []); // Empty dependency array ensures it runs only once

  // @@ Get total amount &&
  const getTotalElumpAmount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/total-amount`
      );
      let tempTotalAmount = response?.data?.totalAmount;
      const provider = new ethers.JsonRpcProvider(RPC_URL_ETH);
      const contract = new ethers.Contract(
        ethContractAddress,
        ElumphantPresaleABI.abi,
        provider
      );

      const amount = await contract.totalTokensPurchased();
      const ethTotal = Number(amount / divisor);

      const bscProvider = new ethers.JsonRpcProvider(RPC_URL_BSC);
      const bscContract = new ethers.Contract(
        bscContractAddress,
        ElumphantPresaleABI.abi,
        bscProvider
      );

      const bscAmount = await bscContract.totalTokensPurchased();
      const bscTotal = Number(bscAmount / divisor);
      let total = ethTotal + bscTotal;
      total = total + tempTotalAmount;
      const leftTokens = (total % 100000000) / 1000000;

      setProgressBarStatus(leftTokens);
      setLeftAmount(100000000 - (total % 100000000));
      setBuyEvent(false);
    } catch (error) {
      console.log("API Total Amount Error: ", error);
      return;
    }
  };
  useEffect(() => {
    getTotalElumpAmount();
  }, [buyEvent, buyAmount]);

  // @@ Catch Buy Event - Notification @@
  useEffect(() => {
    const ethProvider = new ethers.JsonRpcProvider(RPC_URL_ETH);
    const bscProvider = new ethers.JsonRpcProvider(RPC_URL_BSC);

    const ethContract = new ethers.Contract(
      ethContractAddress,
      ElumphantPresaleABI.abi,
      ethProvider
    );
    const bscContract = new ethers.Contract(
      bscContractAddress,
      ElumphantPresaleABI.abi,
      bscProvider
    );

    try {
      ethContract.on("TokensPurchased", (buyer, amount) => {
        if (amount <= 0) return;
        console.log(`ETH Address ${buyer} bought ${amount} of tokens!`);
        setBuyEvent(!buyEvent);
        createNotification("success", minimizeAddress(buyer), amount);
      });
      bscContract.on("TokensPurchased", (buyer, amount) => {
        if (amount <= 0) return;
        console.log(`BSC Address ${buyer} bought ${amount} of tokens!`);
        setBuyEvent(!buyEvent);
        createNotification("success", minimizeAddress(buyer), amount);
      });

      // Get Event whenever price is changed manually
      ethContract.on("TokenPriceUpdated", (oldPrice, newPrice) => {
        console.log("Price >>>", oldPrice, newPrice);
        if (oldPrice === newPrice) {
          console.log("Price did not change.");
          return;
        }
        setChangeElumpPriceEvent(!changeElumpPriceEvent);
        console.log(`Elump Price changed from ${oldPrice} to ${newPrice}!`);
      });
      bscContract.on("TokenPriceUpdated", (oldPrice, newPrice) => {
        if (oldPrice === newPrice) {
          console.log("Price did not change.");
          return;
        }
        console.log(`BSC Elump Price changed from ${oldPrice} to ${newPrice}!`);
        setChangeElumpPriceEvent(!changeElumpPriceEvent);
      });
    } catch (error) {
      console.log("catchBuyEvent Error: ", error);
    }

    // console.log("catchBuyEvent end");
    return () => {
      ethContract.removeAllListeners();
      bscContract.removeAllListeners();
      // console.log("removed all listeners ...");
    };
  }, []);

  // Disconnect Handler
  const disconnectHandler = async () => {
    try {
      await disconnect();
      setInputError("");
      setFailedMessage("");
      setPayAmount("");
      setElumReceiveAmount("");
    } catch (error) {
      console.log("Disconnect Error: ", error);
    }
  };
  // Disconnect when the metamask is locked
  useEffect(() => {
    if (lockStatus === "locked") {
      isConnected === true && disconnectHandler();
    } else if (lockStatus === "unlocked") {
    }
  }, [lockStatus]);
  // @ Get Price of $Elump
  const getPriceHandler = async () => {
    // if (changeElumpPriceEvent === false) return;
    if (chainId == 56 || chainId == 56) {
      const provider = new ethers.JsonRpcProvider(RPC_URL_BSC);

      // Initialize the contract with the provider
      const contract = new ethers.Contract(
        bscContractAddress,
        ElumphantPresaleABI.abi,
        provider
      );
      const price = await contract.tokenPriceInUSD();
      const latestBSC = await contract.getLatestETHPrice();

      setElumPrice(Number(price) / 1000000000000000000);
      setLatestBSCPrice(Number(latestBSC) / 100000000);
      setChangeElumpPriceEvent(false);
    } else {
      const provider = new ethers.JsonRpcProvider(RPC_URL_ETH);

      // Initialize the contract with the provider
      const contract = new ethers.Contract(
        ethContractAddress,
        ElumphantPresaleABI.abi,
        provider
      );
      const price = await contract.tokenPriceInUSD();

      const latestEth = await contract.getLatestETHPrice();
      // const test = ethers.formatEther(latestEth);
      setLatestETHPrice(Number(latestEth) / 100000000);
      setElumPrice(Number(price) / 1000000);
      setChangeElumpPriceEvent(false);
      // Call the function
    }
  };

  useEffect(() => {
    getPriceHandler();
  }, [chainId, changeElumpPriceEvent, isConnected]);

  // @ Swith Handler
  const switchNetworkHandler = async () => {
    setInputError("");

    try {
      if (chainId === 56) {
        if (switchBSCLoading === true || switchETHLoading === true) return;
        console.log("Switching to Ethereum network...");
        setSwitchETHLoading(true);
        setSwitchBSCLoading(false);

        try {
          // Initiate the network switch
          await switchNetwork(networks[0]);
          console.log("Network switch initiated to Ethereum");

          // Polling to check if the chain ID is updated
          const maxRetries = 10; // Maximum attempts
          const interval = 300; // Time in milliseconds between checks
          let retries = 0;

          while (retries < maxRetries) {
            if (chainId === 1) {
              // Ethereum chain ID
              console.log("Successfully switched to Ethereum chain");
              setSwitchETHLoading(false);
              setSelectedCoin(false);
              setElumReceiveAmount("");
              setPayAmount("");
              return;
            }

            console.log("Waiting for Ethereum chain ID to update...");
            await new Promise((resolve) => setTimeout(resolve, interval));
            retries++;
          }

          console.warn(
            "Failed to switch to Ethereum chain within the timeout."
          );
          setSwitchETHLoading(false);
        } catch (error) {
          console.error("Error while switching to Ethereum: ", error);
          setSwitchETHLoading(false);
        }
      } else {
        if (switchBSCLoading === true || switchETHLoading === true) return;
        console.log("Switching to BSC network...");
        setSwitchBSCLoading(true);
        setSwitchETHLoading(false);

        try {
          // Initiate the network switch
          await switchNetwork(networks[1]);
          console.log("Network switch initiated to BSC");

          // Polling to check if the chain ID is updated
          const maxRetries = 10; // Maximum attempts
          const interval = 300; // Time in milliseconds between checks
          let retries = 0;

          while (retries < maxRetries) {
            if (chainId === 56) {
              // BSC chain ID
              console.log("Successfully switched to BSC chain");
              setSwitchBSCLoading(false);
              setSelectedCoin(false);
              setElumReceiveAmount("");
              setPayAmount("");
              return;
            }

            console.log("Waiting for BSC chain ID to update...");
            await new Promise((resolve) => setTimeout(resolve, interval));
            retries++;
          }

          console.warn("Failed to switch to BSC chain within the timeout.");
          setSwitchBSCLoading(false);
        } catch (error) {
          console.error("Error while switching to BSC: ", error);
          setSwitchBSCLoading(false);
        }
      }
    } catch (error) {
      console.error("Switch Network Error: ", error);
      setSwitchETHLoading(false);
      setSwitchBSCLoading(false);
    }
  };

  // @@ ETH -> USDT Handler @@
  const ethHandler = async () => {
    setInputError("");
    setSelectedCoin(false);
    setPayAmount("");
    setElumReceiveAmount("");
    if (selectedNetwork === 1) {
    }
  };
  const usdtHandler = () => {
    console.log("usdtHandler >>>>");
    setInputError("");
    setSelectedCoin(true);
    setPayAmount("");
    setElumReceiveAmount("");
  };

  // @ Get Purchased Amount
  const getPurchasedHandler = async () => {
    if (isConnected === false) return;
    const ethProvider = new ethers.JsonRpcProvider(RPC_URL_ETH);
    const bscProvider = new ethers.JsonRpcProvider(RPC_URL_BSC);
    const ethContract = new ethers.Contract(
      ethContractAddress,
      ElumphantPresaleABI.abi,
      ethProvider
    );
    const bscContract = new ethers.Contract(
      bscContractAddress,
      ElumphantPresaleABI.abi,
      bscProvider
    );

    // Initialize the contract with the provider
    const purchasedOnETH = await ethContract.purchasedTokens(address);
    const purchasedOnBSC = await bscContract.purchasedTokens(address);
    const totalPurchased = purchasedOnETH + purchasedOnBSC;

    setPurchasedAmount(Number(totalPurchased / divisor));
    setTotalPurchasedAmount(Number(totalPurchased / divisor));
    const stakedETH = await ethContract.stakedTokens(address);
    const stakedBSC = await bscContract.stakedTokens(address);
    const myTotalStaked = stakedETH + stakedBSC;
    const totalStakedNumber = Number(myTotalStaked / divisor);
    setMyStakedAmount(totalStakedNumber);
    setBuyEvent(false);
  };
  useEffect(() => {
    if (isConnected === false) {
      return;
    } else {
      getPurchasedHandler();
    }
  }, [chainId, isConnected, buyEvent]);

  // @ Get my wallet balance
  const getMyWalletBalance = async (status) => {
    try {
      if (selectedCoin === false) {
        const provider = new ethers.BrowserProvider(walletProvider);
        const balanceWei = await provider.getBalance(address);
        const balanceEther = ethers.formatEther(balanceWei);
        const amount = parseFloat(balanceEther).toFixed(5).toString();
        setMyBalance(amount);
        // setPayAmount(amount.toString());
      } else {
        const provider = new ethers.BrowserProvider(walletProvider);
        if (selectedNetwork === 1) {
          const usdtContract = new ethers.Contract(
            ethUsdtContractAddress,
            USDT_ABI_ETH.abi,
            provider
          );

          const usdtAmount = await usdtContract.balanceOf(address);
          const usdtBalance = (Number(usdtAmount) / 1000000).toFixed(5);
          setMyBalance(usdtBalance);
          // setPayAmount(usdtBalance.toString())
        } else {
          const usdtContract = new ethers.Contract(
            bscUsdtContractAddress,
            USDT_ABI_BSC.abi,
            provider
          );
          const usdtAmount = await usdtContract.balanceOf(address);
          // @@@TEST
          const usdtBalance = (
            Number(usdtAmount) / 1000000000000000000
          ).toFixed(5);
          setMyBalance(usdtBalance);
          // setPayAmount(usdtBalance.toString())
        }
      }
    } catch (error) {
      console.log("GetMyWalletBalance Error: ", error);
      return;
    }
  };
  useEffect(() => {
    if (isConnected && walletProvider) getMyWalletBalance();
  }, [selectedCoin, chainId, walletProvider, buyStatus, address]);
  // @ Max Handler
  const onMaxHandler = async () => {
    console.log("onMaxHandler>>>");
    if (isConnected === false) return;
    setPayAmount(myBalance.toString());
    try {
      if (selectedCoin === false) {
        if (selectedNetwork == 1) {
          setElumReceiveAmount(
            ((myBalance * latestETHPrice) / elumPrice).toFixed(2)
          );
        } else {
          setElumReceiveAmount(
            ((myBalance * latestBSCPrice) / elumPrice).toFixed(2)
          );
        }
      } else {
        setPayAmount(myBalance);
        setElumReceiveAmount(myBalance / elumPrice);
      }
    } catch (error) {
      console.log("onMaxHandler Error : ", error);
      return;
    }
  };
  // @@@ Modal Handler
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const colseBuyModal = () => {
    setBuyStatus(false);
  };
  const closeClaimModalHandler = () => {
    setOpenClaimModal(false);
  };

  // @@@@@ Connect Wallet Handler @@@@@
  const connectWalletHandler = async (type) => {
    // if (inputError !== "") return;
    // Connect Wallet
    try {
      if (isConnected === false) {
        try {
          await open();
          // setIsConnectedStatus(true);
          setPayAmount("");
          setElumReceiveAmount("");
          setInputError("");
        } catch (error) {
          console.log("Open Wallet Error: ", error);
          return;
        }
      }
      // After Connecting wallet
      else {
        if (!window.ethereum) {
          alert("Please install MetaMask!");
          return;
        }
        if (type === "staking") {
          if (loading === true) {
            setLoading(false);
            return;
          } else if (inputError !== "") {
            setStakingLoading(false);
            return;
          } else {
            console.log("InputError: ", inputError);
            setStakingLoading(true);
            setLoading(false);
          }
        } else if (type === "buy") {
          if (stakingLoading === true) {
            setStakingLoading(false);
            return;
          } else {
            setLoading(true);
            setStakingLoading(false);
          }
        }

        // @@@@@ Check the presale time @@@@@
        if (period === false) {
          handleOpen();
          setLoading(false);
          return;
        }

        if (parseFloat(payAmount) <= 0 || payAmount === "") {
          setInputError("The amount should over than 0");
          setLoading(false);
          setStakingLoading(false);
          return;
        }
        setStakingAmount(elumReceiveAmount);
        setFailedMessage("");
        if (selectedNetwork === 1) {
          try {
            // setConnectWalletLoading(true);
            // Connect to Ethereum provider (MetaMask)
            const provider = new ethers.BrowserProvider(walletProvider);
            const signer = await provider.getSigner();
            if (selectedCoin === false) {
              if (parseFloat(payAmount) * latestETHPrice < 10) {
                setInputError("Amount less than minimum $10");
                setLoading(false);
                setStakingLoading(false);
                return;
              }
              // Create a contract instance using the imported ABI
              const contract = new ethers.Contract(
                ethContractAddress,
                ElumphantPresaleABI.abi,
                signer
              );
              // Specify the ETH amount to send (e.g., 0.1 ETH)
              const ethAmount = ethers.parseEther(payAmount); // Convert ETH to Wei
              console.log("ethAmount >>>", ethAmount, typeof ethAmount);
              // Call the buyWithETH function
              if (type === "buy") {
                setLoading(true);
              }
              const tx = await contract.buyWithETH({ value: ethAmount });
              // Wait for the transaction to be mined
              const receipt = await tx.wait();
              // let stakingAmount = "0n";

              if (type === "staking") {
                setLoading(false);
                try {
                  const stakingAmounts = receipt.logs
                    .map((log) => {
                      try {
                        const parsedLog = contract.interface.parseLog(log);
                        // Ensure the parsedLog has `args` and the desired index exists
                        return parsedLog?.args?.[1];
                      } catch (e) {
                        console.warn("Failed to parse log: ", log, e);
                        return undefined;
                      }
                    })
                    .filter((amount) => amount !== undefined); // Filter out undefined values

                  if (stakingAmounts.length === 0) {
                    throw new Error("No valid staking amounts found in logs.");
                  }

                  // Convert BigInt to string explicitly (if required by contract method)
                  const stakingAmountString = stakingAmounts[0]?.toString();

                  // Ensure the staking amount is valid before proceeding
                  if (!stakingAmountString) {
                    throw new Error("Staking amount is undefined or invalid.");
                  }
                  console.log("Parsed Staking Amount: ", stakingAmounts[0]);

                  const transaction = await contract.claimAndStake(
                    stakingAmountString
                  );
                  const status = await transaction.wait();
                  console.log("Transaction Successful: ", status);
                  setStakingType("BuyAndStaking");
                  setPayAmount("");
                  setElumReceiveAmount("");
                  setBuyEvent(false);
                  setBuyStatus(true);
                } catch (error) {
                  console.error("ETH Staking Error >>>>>", error);
                }
              } else if (type === "buy") {
                setLoading(false);

                setPayAmount("");
                setElumReceiveAmount("");
                setBuyEvent(false);
                setBuyStatus(true);
                console.log("Transaction mined:", receipt);
              }
            } else if (selectedCoin === true) {
              if (payAmount < 10) {
                setLoading(false);
                setStakingLoading(false);
                setInputError("Amount less than minimum $10");
                return;
              } else if (parseFloat(payAmount) > parseFloat(myBalance)) {
                setLoading(false);
                setStakingLoading(false);
                setFailedMessage("");
                setInputError("Your balance is insufficient");
                return;
              }

              const usdtContract = new ethers.Contract(
                ethUsdtContractAddress,
                USDT_ABI_ETH.abi,
                signer
              );
              const usdtAmount = ethers.parseUnits(payAmount, 6);

              // Get the current approval
              const curApproved = await usdtContract.allowance(
                signer.address,
                ethContractAddress
              );
              console.log("curApproved: ", curApproved);

              if (curApproved < usdtAmount) {
                if (curApproved > 0) {
                  // If the current approval is not enough, revoke it first.
                  const approveTx = await usdtContract.approve(
                    ethContractAddress,
                    0
                  );
                  await approveTx.wait();
                }

                // Request a new approval.
                const approveTx = await usdtContract.approve(
                  ethContractAddress,
                  usdtAmount
                );
                await approveTx.wait();
              } else console.log("No need for an additional approval!");

              const presaleContract = new ethers.Contract(
                ethContractAddress,
                ElumphantPresaleABI.abi,
                signer
              );
              const tx = await presaleContract.buyWithUSDT(usdtAmount);
              const receipt = await tx.wait();

              if (type === "staking") {
                const stakingAmount = await receipt.logs
                  .map((log) => {
                    try {
                      const parsedLog =
                        presaleContract?.interface?.parseLog(log);
                      if (parsedLog && parsedLog?.args?.[1]) {
                        console.log("parsedLog USDT >>>", parsedLog?.args?.[1]);
                        return parsedLog.args[1];
                      }
                    } catch (error) {
                      console.error("Error parsing log:", error);
                    }
                    return null; // Return null for invalid logs
                  })
                  .filter((amount) => amount !== null); // Filter out null values

                console.log("All valuable staking amounts >>>", stakingAmount);
                try {
                  const transaction = await presaleContract.claimAndStake(
                    stakingAmount[0] // Convert BigInt to string
                  );
                  const status = await transaction.wait();
                  console.log("Transaction Successful: ", status);
                  setStakingLoading(false);
                  setPayAmount("");
                  setElumReceiveAmount("");
                  setBuyEvent(false);
                  setBuyStatus(true);
                  setStakingType("BuyAndStaking");
                } catch (error) {
                  console.error("ETH USDT Staking Error >>>>>", error);
                  setStakingLoading(false);
                  return;
                }
              } else {
                console.log("Buy With USDT >>>>>>");
                setPayAmount("");
                setElumReceiveAmount("");
                setBuyEvent(false);
                setBuyStatus(true);
                console.log("Transaction mined:", receipt);
              }
            }
          } catch (error) {
            inputError === "" &&
              setFailedMessage(
                "The request could not be processed. Please try again."
              );
            console.error("Error calling buying:", error.message);
            setBuyStatus(false);
            setLoading(false);
            setStakingLoading(false);
            // setConnectWalletLoading(false);

            if (
              error.message
                .toLowerCase()
                .includes("amount less than minimum limit")
            ) {
              setLoading(false);
              setStakingLoading(false);
              setInputError("Amount less than minimum limit - $10");
            } else if (
              error.message.toLowerCase().includes("insufficient funds")
            ) {
              // alert("Minimum purchase is $10");
              setFailedMessage("");
              setInputError("Your balance is insufficient");
              setLoading(false);
              setStakingLoading(false);
            }
          }
        } else if (selectedNetwork === 2) {
          try {
            // setConnectWalletLoading(true);
            // Connect to Ethereum provider (MetaMask)
            const provider = new ethers.BrowserProvider(walletProvider);
            const signer = await provider.getSigner();
            if (selectedCoin === false) {
              if (parseFloat(payAmount) * latestBSCPrice < 10) {
                setInputError("Amount less than minimum $10");
                setLoading(false);
                setStakingLoading(false);
                return;
              }
              // Create a contract instance using the imported ABI
              const contract = new ethers.Contract(
                bscContractAddress,
                ElumphantPresaleABI.abi,
                signer
              );
              // Specify the ETH amount to send (e.g., 0.1 ETH)
              const ethAmount = ethers.parseEther(payAmount); // Convert ETH to Wei
              // Call the buyWithETH function
              const tx = await contract.buyWithETH({ value: ethAmount });
              // Wait for the transaction to be mined
              const receipt = await tx.wait();

              if (type === "staking") {
                try {
                  const stakingAmount = await receipt.logs.map((log) => {
                    const parsedLog = contract.interface.parseLog(log);
                    return parsedLog?.args[1];
                  });
                  console.log("parsedLog: ", stakingAmount[0]);

                  const transaction = await contract.claimAndStake(
                    stakingAmount[0] // Convert BigInt to string
                  );
                  const status = await transaction.wait();
                  console.log("Transaction Successful: ", status);
                  setPayAmount("");
                  setElumReceiveAmount("");
                  setBuyEvent(false);
                  setBuyStatus(true);
                  setStakingType("BuyAndStaking");
                } catch (error) {
                  console.error("BSC Staking Error >>>>>", error);
                }
              } else if (type === "buy") {
                setPayAmount("");
                setElumReceiveAmount("");
                setBuyEvent(false);
                setBuyStatus(true);
                console.log("Transaction mined:", receipt);
              }
            } else if (selectedCoin === true) {
              if (payAmount < 10) {
                setLoading(false);
                setStakingLoading(false);
                setInputError("Minimum purchase is $10");
                return;
              } else if (parseFloat(payAmount) > parseFloat(myBalance)) {
                setLoading(false);
                setStakingLoading(false);
                setFailedMessage("");
                setInputError("Your balance is insufficient");
                return;
              }

              const usdtContract = new ethers.Contract(
                bscUsdtContractAddress,
                USDT_ABI_BSC.abi,
                signer
              );
              const usdtAmount = ethers.parseUnits(payAmount, 18);

              const curApproved = await usdtContract.allowance(
                signer.address,
                bscContractAddress
              );
              console.log("curApproved: ", curApproved);

              if (curApproved < usdtAmount) {
                // Request a new approval.
                const approveTx = await usdtContract.approve(
                  bscContractAddress,
                  usdtAmount
                );
                await approveTx.wait();
              } else console.log("No need for an additional approval!");

              const presaleContract = new ethers.Contract(
                bscContractAddress,
                ElumphantPresaleABI.abi,
                signer
              );
              const tx = await presaleContract.buyWithUSDT(usdtAmount);
              const receipt = await tx.wait();
              if (type === "staking") {
                const stakingAmount = await receipt.logs
                  .map((log) => {
                    try {
                      const parsedLog =
                        presaleContract?.interface?.parseLog(log);
                      if (parsedLog && parsedLog?.args?.[1]) {
                        console.log("parsedLog USDT >>>", parsedLog?.args?.[1]);
                        return parsedLog.args[1];
                      }
                    } catch (error) {
                      console.error("Error parsing log:", error);
                    }
                    return null; // Return null for invalid logs
                  })
                  .filter((amount) => amount !== null); // Filter out null values

                console.log("BSC USDT staking amounts >>>", stakingAmount);
                try {
                  const transaction = await presaleContract.claimAndStake(
                    stakingAmount[0] // Convert BigInt to string
                  );
                  const status = await transaction.wait();
                  console.log("Transaction Successful: ", status);
                  setStakingLoading(false);
                  setPayAmount("");
                  setElumReceiveAmount("");
                  setBuyEvent(false);
                  setBuyStatus(true);
                  setStakingType("BuyAndStaking");
                } catch (error) {
                  console.error("BSC USDT Staking Error >>>>>", error);
                  setStakingLoading(false);
                  return;
                }
              } else {
                console.log("Buy With USDT >>>>>>");
                setPayAmount("");
                setElumReceiveAmount("");
                setBuyEvent(false);
                setBuyStatus(true);
                console.log("Transaction mined:", receipt);
              }
            }
          } catch (error) {
            console.error("Error calling buying:", error.message);
            // setConnectWalletLoading(false);
            inputError === "" &&
              setFailedMessage(
                "The request could not be processed. Please try again."
              );
            setBuyStatus(false);
            setLoading(false);
            setStakingLoading(false);
            if (error.message.toLowerCase().includes("missing revert")) {
              setFailedMessage("");
              setInputError("Your balance is insufficient");
            } else if (
              error.message
                .toLowerCase()
                .includes("amount less than minimum limit")
            ) {
              setInputError("Minimum purchase is $10");
            }
          }
        }
        setLoading(false);
        setStakingLoading(false);
      }
    } catch (error) {
      console.log("Connect Wallet Handler Error: ", error);
    }
  };
  // @ Change Handler in Buy Section
  const onChangeHandler = (e) => {
    // if (isConnected === false) return;
    setFailedMessage("");
    const value = e.target.value;
    if (parseFloat(value) >= 0) {
      setInputError("");
    }
    if (value === "" || value === 0) {
      setInputError("");
    }

    if (selectedCoin === false) {
      if (selectedNetwork === 1 && parseFloat(value) * latestETHPrice < 10) {
        setInputError("The minimum amount is $10");
      } else if (selectedNetwork === 1 && parseFloat(value) > myBalance) {
        setInputError("Your balance is insufficient");
      } else if (
        selectedNetwork === 2 &&
        parseFloat(value) * latestBSCPrice < 10
      ) {
        setInputError("The minimum amount is $10");
      } else if (selectedNetwork === 2 && parseFloat(value) > myBalance) {
        setInputError("Your balance is insufficient");
      }
    } else {
      if (selectedNetwork === 1 && parseFloat(value) < 10) {
        setInputError("The minimum amount is $10");
      } else if (selectedNetwork === 1 && parseFloat(value) > myBalance) {
        setInputError("Your balance is insufficient");
      } else if (selectedNetwork === 2 && parseFloat(value) < 10) {
        setInputError("The minimum amount is $10");
      } else if (selectedNetwork === 2 && parseFloat(value) > myBalance) {
        setInputError("Your balance is insufficient");
      }
    }
    setPayAmount(value);
    if (selectedCoin === false) {
      if (selectedNetwork === 1) {
        setElumReceiveAmount(((value * latestETHPrice) / elumPrice).toFixed(2));
      } else {
        setElumReceiveAmount(((value * latestBSCPrice) / elumPrice).toFixed(2));
      }
    } else {
      setElumReceiveAmount((value / elumPrice).toFixed(2));
    }
  };
  const onChangeElumpHandler = (e) => {
    const value = e.target.value;
    // if (isConnected === false) return;
    console.log("Value >>>", typeof value);
    /*console.log("selectedCoin: ", selectedCoin);
    console.log("selectedNetwork: ", selectedNetwork);
    console.log("latestETHPrice: ", latestETHPrice);
    console.log("latestBSCPrice: ", latestBSCPrice);*/

    setInputError("");
    setElumReceiveAmount(value);

    if (selectedCoin === false) {
      if (selectedNetwork === 1) {
        const payWithValue = (value * elumPrice) / latestETHPrice;
        if (value * elumPrice < 10) {
          setInputError("The minimum amount is $10");
        } else if (value * elumPrice > myBalance * latestETHPrice) {
          setInputError("Your balance is insufficient");
        }
        setPayAmount(payWithValue.toFixed(6));
      } else {
        const payWithValue = (value * elumPrice) / latestBSCPrice;
        if (value * elumPrice < 10) {
          setInputError("The minimum amount is $10");
        } else if (value * elumPrice > myBalance * latestETHPrice) {
          setInputError("Your balance is insufficient");
        }
        setPayAmount(payWithValue.toFixed(6));
      }
    } else {
      const payWithValue = value * elumPrice;
      if (payWithValue < 10) {
        setInputError("The minimum amount is $10");
      } else if (payWithValue > myBalance) {
        setInputError("Your balance is insufficient");
      }
      setPayAmount(payWithValue.toFixed(4));
    }

    if (value === "") {
      console.log("Undefined >>>");
      setInputError("");
      setPayAmount("");
    } else if (value === "0") {
      console.log("Nan >>>");
      setInputError("");
      setPayAmount("");
    }
  };

  const onCliamHandler = async () => {
    setOpenClaimModal(true);
  };
  const copyAddressHandler = async () => {
    console.log("Address >>>>", address);

    if (!navigator.clipboard || !navigator.clipboard.writeText) {
      console.warn("Clipboard API not supported. Using fallback.");
      // Fallback: Use a hidden textarea to copy the address
      const textArea = document.createElement("textarea");
      textArea.value = address;
      textArea.style.position = "fixed"; // Prevent scrolling
      textArea.style.opacity = "0"; // Make invisible
      document.body.appendChild(textArea);
      textArea.select();
      try {
        const success = document.execCommand("copy"); // Use fallback method
        if (success) {
          setCopiedAddress(true); // Set copied state to true on success
        } else {
          setCopiedAddress(false);
          console.log("Fallback copy failed. Please copy manually.");
        }
      } catch (err) {
        console.error("Fallback copy failed: ", err);
        setCopiedAddress(false);
      }
      document.body.removeChild(textArea);
      return;
    }

    // Clipboard API
    try {
      await navigator.clipboard.writeText(address);
      setCopiedAddress(true);
      setTimeout(() => setCopiedAddress(false), 1000);
      console.log("Address copied successfully!");
    } catch (err) {
      console.error("Failed to copy address: ", err);
    }
  };

  // Generate Stars
  const [stars, setStars] = useState([]); // State to store generated stars

  useEffect(() => {
    // Generate stars only once when the component mounts
    const generateRandomStars = (numStars) => {
      const starsArray = [];
      for (let i = 0; i < numStars; i++) {
        const randomTop = Math.random() * 100; // Random percentage for top
        const randomLeft = Math.random() * 100; // Random percentage for left
        const randomSize = Math.random() * 1.5 + 0.5; // Random size multiplier

        starsArray.push(
          <TbStarFilled
            key={i}
            style={{
              position: "absolute",
              top: `${randomTop}%`,
              left: `${randomLeft}%`,
              transform: `scale(${randomSize})`,
              color: "white",
            }}
          />
        );
      }
      return starsArray;
    };

    setStars(generateRandomStars(25)); // Generate 25 stars
  }, []); // Empty dependency array ensures this runs only once
  const goToStakingHandler = () => {
    history.push("/staking#stakingSection");
  };

  /* @@@DOM@@@ */
  return (
    <div className="" id="home">
      <div className="section home_image">
        <a href="#buySection" className="scroll-downs">
          <div className="mousey">
            <div className="scroller"></div>
          </div>
        </a>
      </div>
      <AlertModal
        isOpen={alertModalOpen}
        onClose={() => {
          setAlertModalOpen(false);
        }}
      />
      <div id="buySection" className="section hero_container">
        {stars}
        <div className="hero_left">
          <Modal isOpen={isOpen} onClose={handleClose} />
          <BuyModal
            isOpen={buyStatus}
            onClose={colseBuyModal}
            stakingAmount={stakingAmount}
            type={stakingType}
          />
          <ClaimModal
            isOpen={openClaimModal}
            onClose={closeClaimModalHandler}
          />
          {/* Buy Section - Start */}
          <div className="buy-section">
            {period === false ? (
              <div>
                <img src={RedCrownIcon} width={28} height={28} alt="" />
              </div>
            ) : (
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <img src={RedCrownIcon} width={28} height={28} alt="" />
                <div
                  style={{
                    color: "gold",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  Road to Triumph: The Countdown to Feb 28th
                </div>
                <img src={RedCrownIcon} width={28} height={28} alt="" />
              </div>
            )}

            <h6
              className="funny_buy_font"
              style={{
                marginBottom: 0,
                fontFamily: "'Shantell Sans', sans-serif",
              }}
            >
              Presale {period === true ? "Ends" : "Starts"} in
            </h6>
            <Timer setPeriod={memoizedSetPeriod} />
            {period === true && (
              <div className="progress_text">
                $Elump price rises after{" "}
                <span style={{ color: "gold" }}>
                  {formatNumber(leftAmount.toFixed(0))}{" "}
                </span>
                tokens sold. Join the early ride!
              </div>
            )}

            {period === true && (
              <div style={{ width: "70%" }} className="bar_container">
                <div
                  className="bar_content"
                  style={{ width: `${progressBarStatus}%` }}
                ></div>
              </div>
            )}

            <h6
              className="funny_buy_font"
              style={{ paddingTop: "3px", marginBottom: "-5px" }}
            >
              Upcoming Tokens
            </h6>
            {purchasedAmount > 0 && (
              <div
                className="switch_network"
                style={{ marginTop: "-10px !important" }}
              >
                <div
                  style={{ marginTop: "0px !important" }}
                  className="switch_sol"
                  onClick={onCliamHandler}
                >
                  <div style={{ fontSize: "14px" }}>Claim $Elump</div>
                </div>
              </div>
            )}
            <div
              style={{ paddingTop: "7px" }}
              className="detail_text funny_font"
            >
              Your purchased $Elump:{" "}
              {isConnected === true ? formatNumber(purchasedAmount) : 0}{" "}
            </div>
            <div className="detail_text funny_font text-center">
              Your staking $Elump:{" "}
              {isConnected === true ? formatNumber(myStakedAmount) : 0}
              <br /> {}{" "}
            </div>
            <div className="price_elump">
              <div className="white_line"></div>
              <div
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
                className="funny_font"
              >
                <div>1 $Elump = ${elumPrice}</div>
              </div>
              <div className="white_line"></div>
            </div>
            <div className="text-center">
              <span style={{ color: "white", fontSize: "14px" }}>
                Want To Stake $Elump?
              </span>{" "}
              <span onClick={goToStakingHandler} className="go_staking_button">
                Go To Staking Page
              </span>
            </div>
            <div className="selected_crypto">
              <div
                className={`${
                  selectedCoin === true
                    ? "main_network"
                    : "main_network__selected"
                }`}
                onClick={ethHandler}
              >
                <img
                  src={
                    selectedNetwork === 1
                      ? ethLogo
                      : selectedNetwork === 2
                      ? bnbLogo
                      : solanaLogo
                  }
                  width={20}
                  height={20}
                  alt=""
                />
                <span style={{ fontSize: "14px", marginLeft: "6px" }}>
                  {selectedNetwork === 1
                    ? "ETH"
                    : selectedNetwork === 2
                    ? "BNB"
                    : "Solana"}
                </span>
              </div>
              <div
                className={`${
                  selectedCoin === false
                    ? "usdt_network"
                    : "usdt_network__selected"
                }`}
                onClick={usdtHandler}
              >
                <img
                  src={selectedNetwork === 1 ? ethUsdtLogo : bscUsdtLogo}
                  width={24}
                  height={24}
                  alt=""
                />
                <span style={{ fontSize: "14px", marginLeft: "6px" }}>
                  USDT
                </span>
              </div>
            </div>
            <div className="network_value">
              <div className="network_send">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    paddingRight: "15px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div className="inpu_send_detail">
                      Pay with{" "}
                      {selectedCoin === true
                        ? "USDT"
                        : selectedNetwork === 1
                        ? "ETH"
                        : selectedNetwork === 2
                        ? "BNB"
                        : "Solana"}
                    </div>
                    <span>
                      {payAmount === "0" ? (
                        <div className="my_wallet_balance">$0</div>
                      ) : payAmount === "0" ? (
                        ""
                      ) : (
                        <div className="my_wallet_balance">
                          $
                          {payAmount === ""
                            ? 0
                            : selectedCoin === false
                            ? selectedNetwork === 1
                              ? formatNumber(
                                  (
                                    latestETHPrice * parseFloat(payAmount)
                                  ).toFixed(2)
                                )
                              : formatNumber(
                                  (
                                    latestBSCPrice * parseFloat(payAmount)
                                  ).toFixed(2)
                                )
                            : formatNumber(parseFloat(payAmount))}
                        </div>
                      )}
                    </span>
                  </div>
                  <div
                    onClick={onMaxHandler}
                    className="max_btn"
                    style={{
                      color: "gold",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    Max
                  </div>
                </div>

                <div className="send_input">
                  <input
                    type="text"
                    value={payAmount}
                    onChange={onChangeHandler}
                    placeholder="0"
                    required
                  />
                  <div className="network_icon">
                    <img
                      src={
                        selectedCoin === true
                          ? selectedNetwork === 1
                            ? ethUsdtLogo
                            : bscUsdtLogo
                          : selectedNetwork === 1
                          ? ethLogo
                          : selectedNetwork === 2
                          ? bnbLogo
                          : solanaLogo
                      }
                      width={selectedNetwork === 1 ? 24 : 22}
                      height={selectedCoin === 1 ? 24 : 22}
                      alt=""
                    />
                  </div>
                </div>
                {isConnected === true && (
                  <div className="valid_input">{inputError}</div>
                )}
              </div>
              <div className="network_receive">
                <div className="input_detail hide-spinner">
                  $Elump you receive
                </div>
                <div className="receive_input">
                  <input
                    type="number"
                    className="hide-spinner"
                    value={elumReceiveAmount}
                    placeholder="0"
                    onChange={onChangeElumpHandler}
                    required
                  />
                  <div className="network_icon">
                    <img src={elephantLogo} width={24} height={24} alt="" />
                  </div>
                </div>
              </div>
            </div>

            {/* Get My Wallet Balance Display - Start */}
            {isConnected === true && (
              <div className="my_wallet_info">
                <span style={{ color: "white" }}>Your Balance: </span>{" "}
                {selectedCoin === false ? (
                  selectedNetwork === 1 ? (
                    <div>
                      <img src={etherIcon} width={20} height={20} alt="" />{" "}
                      <span>
                        {parseFloat(myBalance) == 0
                          ? "0"
                          : formatNumber(myBalance)}
                        <span style={{ color: "white" }}> ETH</span>
                      </span>
                    </div>
                  ) : (
                    <div>
                      <img src={bnbLogo} width={20} height={20} alt="" />{" "}
                      <span>
                        {parseFloat(myBalance) == 0
                          ? "0"
                          : formatNumber(myBalance)}
                        <span style={{ color: "white" }}> BNB</span>
                      </span>
                    </div>
                  )
                ) : selectedNetwork === 1 ? (
                  <div>
                    <img src={ethUsdtLogo} width={20} height={20} alt="" />{" "}
                    <span>
                      {parseFloat(myBalance) == 0
                        ? "0"
                        : formatNumber(myBalance)}
                      <span style={{ color: "white" }}> USDT</span>
                    </span>
                  </div>
                ) : (
                  <div>
                    <img src={bscUsdtLogo} width={20} height={20} alt="" />{" "}
                    <span>
                      {parseFloat(myBalance) == 0
                        ? "0"
                        : formatNumber(myBalance)}
                      <span style={{ color: "white" }}> USDT</span>
                    </span>
                  </div>
                )}
              </div>
            )}
            {isConnected === true && (
              <div className="address_title">
                Your Address:{" "}
                <span onClick={copyAddressHandler} className="address_text">
                  {minimizeAddress(address)}
                </span>
                <span onClick={copyAddressHandler} className="copy_icon">
                  <LuCopy color={copiedAddress ? "gold" : "white"} />
                </span>
              </div>
            )}
            {/* Get My Wallet Balance Display - End */}

            {/* {isConnected === true && <div style={{ color: "white", paddingTop: "10px" }}><appkit-button color="red" /></div>} */}
            <div
              style={{ paddingTop: "5px", fontSize: "16px" }}
              className="valid_input"
            >
              {failedMessage}
            </div>
            <div className="buy_stak_buttons">
              <div
                onClick={() => {
                  loading === false &&
                    stakingLoading === false &&
                    connectWalletHandler("buy");
                }}
                className={
                  isConnected === false
                    ? "connect_button"
                    : inputError === ""
                    ? "buy_button"
                    : "buy_button_disabled"
                }
                // className="buy_button"
              >
                {isConnected === false ? (
                  "Connect Wallet"
                ) : loading === false ? (
                  "Buy"
                ) : (
                  <div>
                    <MoonLoader
                      color="white" // Color of the spinner
                      size={24}
                      loading={loading} // Boolean to show/hide loader
                      height={5} // Height of each bar
                      width={5} // Width of each bar
                      radius={1} // Radius of bar edges
                      speedMultiplier={0.5} // Adjust the speed of the animation
                    />
                  </div>
                )}
              </div>
              <div
                onClick={() => {
                  stakingLoading === false &&
                    loading === false &&
                    connectWalletHandler("staking");
                }}
                className={
                  isConnected === false
                    ? "isConnected_false"
                    : inputError === ""
                    ? "buy_button"
                    : "buy_button_disabled"
                }
                // className="buy_button"
              >
                {isConnected === false ? (
                  ""
                ) : stakingLoading === false ? (
                  "Buy And Stake"
                ) : (
                  <div>
                    <MoonLoader
                      color="white" // Color of the spinner
                      size={24}
                      loading={stakingLoading} // Boolean to show/hide loader
                      height={5} // Height of each bar
                      width={5} // Width of each bar
                      radius={1} // Radius of bar edges
                      speedMultiplier={0.5} // Adjust the speed of the animation
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="switch_network">
              <div className="switch_bnb" onClick={switchNetworkHandler}>
                <div className="swith_to_network">
                  {switchBSCLoading === false &&
                    switchETHLoading === false &&
                    "Switch To"}{" "}
                  {switchBSCLoading === true ? (
                    <MoonLoader
                      color="white" // Color of the spinner
                      size={16}
                      loading={switchBSCLoading} // Boolean to show/hide loader
                      height={5} // Height of each bar
                      width={5} // Width of each bar
                      radius={1} // Radius of bar edges
                      speedMultiplier={0.5} // Adjust the speed of the animation
                    />
                  ) : switchETHLoading === true ? (
                    <MoonLoader
                      color="white" // Color of the spinner
                      size={16}
                      loading={switchETHLoading} // Boolean to show/hide loader
                      height={5} // Height of each bar
                      width={5} // Width of each bar
                      radius={1} // Radius of bar edges
                      speedMultiplier={0.5} // Adjust the speed of the animation
                    />
                  ) : selectedNetwork === 2 ? (
                    "ETH"
                  ) : (
                    "BNB"
                  )}
                </div>
                {switchBSCLoading === false && switchETHLoading === false && (
                  <img
                    src={selectedNetwork === 2 ? ethLogo : bnbLogo}
                    width={20}
                    height={20}
                    alt=""
                  />
                )}
              </div>
              {/* <div className="switch_sol" onClick={switchSolanaHandler}>
                    <div>Switch To {selectedNetwork === 3 ? "ETH" : "Solana"}</div>
                    <img src={selectedNetwork === 3 ? ethLogo : solanaLogo} width={20} height={20} alt="" />
                  </div> */}
            </div>
            {isConnected === true && (
              <div className="switch_network">
                <div className="switch_sol" onClick={disconnectHandler}>
                  <div>Disconnect</div>
                </div>
              </div>
            )}
          </div>
          {/* Buy Section - End */}
        </div>
        <div className="elephantLogo">
          <TextSlider />
          <img draggable="false" width={"85%"} src={homeLogoGif} alt="" />
        </div>
      </div>
      <div
        className="arrow_left_btn"
        onClick={() => {
          history.push("/staking");
        }}
      >
        <IoIosArrowDropleftCircle size={50} color="gold" />
      </div>
      <div
        className="arrow_right_btn"
        onClick={() => {
          history.push("/about");
        }}
      >
        <IoIosArrowDroprightCircle size={50} color="gold" />
      </div>
      <div className="section">
        <Footer />
      </div>
    </div>
  );
};

export default SecHeroSection;
