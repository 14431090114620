import { useEffect, useState } from "react";
import { addRemoveClassBody } from "../../utils";

import dataContact from "../../data/data-containers/HomeDemo2/data-contact.json";
import IcoName from "../../data/data-layout/Footer/data-IcoName.json";
import TextFooter from "../../data/data-layout/Footer/data-TextFooter.json";

import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";

import HowToBuySection from "../../components/HowToBuySection/index.jsx";

const HowToBuy = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    // Update the height on window resize
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    addRemoveClassBody("darker");
  }, []);
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling
    });
  }, []); // Run only on mount
  return (
    <div>
      <div id="buy">
        <HowToBuySection />
      </div>
    </div>
  );
};

export default HowToBuy;
