import { Helmet } from "react-helmet";
import { Switch, Route, useHistory } from "react-router-dom";
import Aos from "aos";

import Main from "./template/Main";
import HowToBuy from "./template/HowToBuy";

import "aos/dist/aos.css";
import "./assets/css/general.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/responsive.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import StakingPage from "./template/StakingPage";

import "react-notifications/lib/notifications.css";
import About from "./template/About/Index";
import Layout from "./layouts";
import WhitePaper from "./template/WhitePaper";
import RoadMap from "./template/RoadMap";

import { useEffect, useState } from "react";
import { addRemoveClassBody } from "./utils";
import axios from "axios";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { createAppKit } from "@reown/appkit/react";
import { networks, projectId, metadata, ethersAdapter } from "../src/config";
// import { CSSTransition, TransitionGroup } from "react-transition-group";

import { useModal } from "../src/ContextProvider";
import "./Fade.css"; // App DOM

console.log("creating app kit");
createAppKit({
  adapters: [ethersAdapter],
  networks,
  metadata,
  projectId,
  autoConnect: false,
  features: {
    analytics: true, // Optional - defaults to your Cloud configuration
    socials: false,
    email: false,
    emailShowWallets: true,
  },
  // allWallets: 'HIDE',
});

const App = () => {
  const history = useHistory();

  const { isOpen, setIsOpen, setBuyAmount, buyAmount } = useModal();

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  function minimizeAddress(addr) {
    return addr.substring(0, 6) + "..." + addr.substring(addr.length - 4);
  }
  useEffect(() => {
    addRemoveClassBody("darker");
  }, []);

  const [error, setError] = useState("");
  // fetchWallet Real Time
  const fetchWallet = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/wallets`
      );

      if (!response?.data?.buyer || !response?.data?.amount) {
        return;
      }

      setBuyAmount(parseFloat(response?.data.amount));
      createNotification(
        "success",
        minimizeAddress(response?.data.buyer),
        response?.data?.amount
      );
    } catch (err) {
      setError("Failed to fetch wallet data");
      console.error("Error fetching wallet:", err);
      return;
    }
  };

  const createNotification = (type, buyerAddress, buyerAmount) => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(
          `Address ${buyerAddress} bought ${buyerAmount} of tokens!`,
          "Success"
        );
        break;
      case "warning":
        NotificationManager.warning(
          "Warning message",
          "Close after 3000ms",
          3000
        );
        break;
      case "error":
        NotificationManager.error("Error message", "Click me!", 5000, () => {
          alert("callback");
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Fetch wallet data initially
    fetchWallet();
    // Set an interval to fetch wallet data every 5 seconds
    const interval = setInterval(fetchWallet, 3000);
    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="App">
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>NFT Marketplace</title>
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          integrity="sha256-eZrrJcwDc/3uDhsdt61sL2oOBY362qM3lon1gyExkL0="
          crossorigin="anonymous"
        />
      </Helmet>
      {/* <TransitionGroup> */}
      {/* <CSSTransition
          key={history.location.key}
          classNames="fade"
          timeout={500}
        > */}
      <Switch>
        <Layout>
          <Route path="/" exact component={Main} />
          <Route path="/about" exact component={About} />
          <Route path="/how-to-buy" exact component={HowToBuy} />
          <Route path="/whitepaper" exact component={WhitePaper} />
          <Route path="/roadmap" exact component={RoadMap} />
          <Route path="/staking" exact component={StakingPage} />
        </Layout>
      </Switch>
      {/* </CSSTransition> */}
      {/* </TransitionGroup> */}
    </div>
  );
};

export default App;
