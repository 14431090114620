import React, { useState, useEffect } from "react";
import "./Timer.scss";

const Timer = ({ setPeriod }) => {
  const now = new Date();
  const [day, setDay] = useState(now.getDay());
  const [hours, setHours] = useState(now.getHours());
  const [minutes, setMinutes] = useState(now.getMinutes());
  const [seconds, setSeconds] = useState(now.getSeconds());

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const presaleStart = new Date("2024-12-06T12:00:00.000Z");
      const saleEnd = new Date("2025-02-28T12:00:00.000Z");
      const diff = presaleStart - now;

      if (diff < 0) {
        setPeriod(true); // Presale has started

        const presaleDiff = saleEnd - now;
        if (presaleDiff < 0) {
          setDay(0);
          setHours(0);
          setMinutes(0);
          setSeconds(0);
          return;
        }

        const days = String(
          Math.floor(presaleDiff / 1000 / 60 / 60 / 24)
        ).padStart(2, "0");
        const hours = String(
          Math.floor(presaleDiff / 1000 / 60 / 60) % 24
        ).padStart(2, "0");
        const minutes = String(
          Math.floor(presaleDiff / 1000 / 60) % 60
        ).padStart(2, "0");
        const seconds = String(Math.floor(presaleDiff / 1000) % 60).padStart(
          2,
          "0"
        );

        setDay(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
      } else {
        const days = String(Math.floor(diff / 1000 / 60 / 60 / 24)).padStart(
          2,
          "0"
        );
        const hours = String(Math.floor(diff / 1000 / 60 / 60) % 24).padStart(
          2,
          "0"
        );
        const minutes = String(Math.floor(diff / 1000 / 60) % 60).padStart(
          2,
          "0"
        );
        const seconds = String(Math.floor(diff / 1000) % 60).padStart(2, "0");

        setDay(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [setPeriod]);

  return (
    <div className="counter_down_content">
      <div className="timer-date">
        <div className="counter_element">
          {day}
          <div>Days</div>
        </div>
        <span>:</span>
        <div className="counter_element">
          {hours}
          <div>Hours</div>
        </div>
        <span>:</span>
        <div className="counter_element">
          {minutes}
          <div>Minutes</div>
        </div>
        <span>:</span>
        <div className="counter_element">
          {seconds}
          <div>Seconds</div>
        </div>
      </div>
    </div>
  );
};

export default Timer;
