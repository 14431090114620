import React, { useState, useEffect } from "react";
import "./Roadmap.scss";
import RoadmapCard from "../RoadmapCard";
import { roadmapCardData } from "../../data/data-roadmap/data-roadCard";
import Footer from "../../layouts/Footer";
import { TbStarFilled } from "react-icons/tb";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { useHistory } from "react-router-dom";

const Roadmap = ({}) => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling
    });
  }, []); // Run only on mount
  //
  const [stars, setStars] = useState([]); // State to store generated stars

  useEffect(() => {
    // Generate stars only once when the component mounts
    const generateRandomStars = (numStars) => {
      const starsArray = [];
      for (let i = 0; i < numStars; i++) {
        const randomTop = Math.random() * 100; // Random percentage for top
        const randomLeft = Math.random() * 100; // Random percentage for left
        const randomSize = Math.random() * 1.5 + 0.5; // Random size multiplier

        starsArray.push(
          <TbStarFilled
            key={i}
            style={{
              position: "absolute",
              top: `${randomTop}%`,
              left: `${randomLeft}%`,
              transform: `scale(${randomSize})`,
              color: "white",
            }}
          />
        );
      }
      return starsArray;
    };

    setStars(generateRandomStars(30)); // Generate 25 stars
  }, []); // Empty dependency array ensures this runs only once
  return (
    <div className="roadmap_main" id="roadmap">
      <div className="arrow_buttons">
        <div
          className="arrow_btn"
          onClick={() => {
            history.push("/whitepaper");
          }}
        >
          <IoIosArrowDropleftCircle size={50} color="gold" />
        </div>

        <div
          className="arrow_btn"
          onClick={() => {
            history.push("/staking");
          }}
        >
          <IoIosArrowDroprightCircle size={50} color="gold" />
        </div>
      </div>
      <div className="section roadmap_image">
        <a href="#roadmapSection" className="scroll-downs">
          <div className="mousey">
            <div className="scroller"></div>
          </div>
        </a>
      </div>
      {/* <div className="mask_effect_roadmap"></div> */}
      <div id="roadmapSection" className="section container_roadmap">
        <div>{stars}</div>

        <div className="section_title">
          <span style={{ zIndex: 99 }}>RoadMap</span>
        </div>
        {roadmapCardData.map((item, id) => (
          <RoadmapCard
            key={id}
            date={item.date}
            type={item.type}
            index={item.index}
            img={item.img}
            content={item.content}
          />
        ))}
      </div>
      <div className="section">
        <Footer />
      </div>
    </div>
  );
};

export default Roadmap;
