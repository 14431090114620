import {
  roadmapImg2,
  roadmapImg1,
  roadmapImg3,
  roadmapImg4,
  aboutLogoImg,
} from "../../utils/allImgs";

export const roadmapCardData = [
  {
    date: "2024 Q4:",
    type: true,
    content: `1. Smart Contract Development and Mainnet Deployment: Develop and deploy the Elumphant smart contract to the mainnet. \n 2. Build Website: Launch an engaging and user-friendly website. \n 3. Establish Social Channels: Create and grow Twitter, Telegram, and other channels to foster community. `,
    index: 1,
    img: roadmapImg4,
  },
  {
    date: "2025 Q1",
    type: false,
    content: `1. NFT Art Creation: Create 10,000 NFTs with 4 rarity levels to reward and engage the community. \n 2. Community Engagement Campaigns: Drive community storytelling around Elumphant, especially around Jan 20th, commemorating the Trump administration. \n 3. DEX Listings and Liquidity Addition: List $Elump tokens on major decentralized exchanges like Uniswap and PancakeSwap. \n 4. Staking Launch: Launch staking with high APY rewards to encourage holding and strengthen the community. `,
    index: 2,
    img: roadmapImg2,
  },
  {
    date: "2025 Q2",
    type: true,
    content: `1. NFT Airdrop: Conduct the exclusive NFT airdrop to early buyers and the top 200 rich list. \n 2. Further Community Growth: Expand community channels with engaging events, meme contests, and giveaways.`,
    index: 3,
    img: aboutLogoImg,
  },
  {
    date: "2025 Q3:",
    type: false,
    content: `1. Centralized Exchange Listings: Begin listing on major centralized exchanges to increase accessibility. \n 2. Partnership Announcements: Collaborate with other projects to enhance Elumphant’s ecosystem.`,
    index: 4,
    img: roadmapImg1,
  },
];
