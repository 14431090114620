import React, { useState, useEffect } from "react";
import "./WhitePaper.scss";
import { whitepaperGIF } from "../../utils/allImgs";
import Footer from "../../layouts/Footer";
import { TbStarFilled } from "react-icons/tb";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { useHistory } from "react-router-dom";

const WhitePaperComponent = () => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling
    });
  }, []); // Run only on mount
  // Generate Stars
  const [stars, setStars] = useState([]); // State to store generated stars

  useEffect(() => {
    // Generate stars only once when the component mounts
    const generateRandomStars = (numStars) => {
      const starsArray = [];
      for (let i = 0; i < numStars; i++) {
        const randomTop = Math.random() * 100; // Random percentage for top
        const randomLeft = Math.random() * 100; // Random percentage for left
        const randomSize = Math.random() * 1.5 + 0.5; // Random size multiplier

        starsArray.push(
          <TbStarFilled
            key={i}
            style={{
              position: "absolute",
              top: `${randomTop}%`,
              left: `${randomLeft}%`,
              transform: `scale(${randomSize})`,
              color: "white",
            }}
          />
        );
      }
      return starsArray;
    };

    setStars(generateRandomStars(25)); // Generate 25 stars
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div className="" id="whitepaper">
      <div className="arrow_buttons">
        <div
          className="arrow_btn"
          onClick={() => {
            history.push("/how-to-buy");
          }}
        >
          <IoIosArrowDropleftCircle size={50} color="gold" />
        </div>

        <div
          className="arrow_btn"
          onClick={() => {
            history.push("/roadmap");
          }}
        >
          <IoIosArrowDroprightCircle size={50} color="gold" />
        </div>
      </div>
      <div className="section whitepaper_image">
        <a href="#whitepaperSection" className="scroll-downs">
          <div className="mousey">
            <div className="scroller"></div>
          </div>
        </a>
      </div>
      {/* <div className="mask_effect_whitepaper" /> */}
      <div id="whitepaperSection" className="section ">
        <div className="whitepaper_container">
          <div className="whitepaper_left" style={{ zIndex: 99 }}>
            <div className="whitepaper_title" style={{ zIndex: 99 }}>
              <span>Elumphant's Battle for Meme Freedom</span>
            </div>
            <div className="whitepaper_content" style={{ zIndex: 99 }}>
              <span>
                Welcome to Elumphant—the mighty, muscular meme elephant who
                marches to triumphant! With Trump's boldness, Elon's ambition,
                and a spirit of unyielding strength, Elumphant is here to storm
                the crypto jungle. Its motto:{" "}
              </span>
              <span style={{ fontWeight: "bold", color: "gold" }}>
                OF THE CRYPTO, BY THE CRYPTO, FOR THE CRYPTO!
              </span>{" "}
              Join the journey—this adventure is unstoppable!
            </div>
            <div className="whitepaper_btn">
              <a
                href="/whitepaper.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                WhitePaper
              </a>
            </div>
          </div>
          <div className="whitepaper_right">
            <div>{stars}</div>
            <img src={whitepaperGIF} width={"80%"} alt="" />
          </div>
          {/* <div style={{ zIndex: 0 }}>{stars}</div> */}
        </div>
      </div>
      <div className="section">
        <Footer />
      </div>
    </div>
  );
};

export default WhitePaperComponent;
