import React from "react";
import Roadmap from "../../components/Roadmap";
import RoadmapInfo from "../../data/data-containers/HomeDemo2/data-RoadmapInfo.json";

const RoadMap = () => {
  return (
    <div>
      <Roadmap data={RoadmapInfo} />
    </div>
  );
};

export default RoadMap;
