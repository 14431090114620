import { RiTwitterXFill } from "react-icons/ri";
import { FaTelegramPlane } from "react-icons/fa";
import "./Footer.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useModal } from "../../ContextProvider";
import Marquee from "react-fast-marquee";
import { useHistory } from "react-router-dom";

import {
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
} from "../../utils/allImgs";
const Footer = ({}) => {
  const { setIsOpen, isOPen, setPreventAlertModal, preventAlertModal } =
    useModal();
  const history = useHistory();

  return (
    <div className="section footer_flag">
      <div className="footer_features">
        <Marquee
          style={{
            color: "black",
            gap: 30,
            backgroundColor: "gold",
            fontSize: "20px",
            marginBottom: "15px",
          }}
        >
          <div style={{ marginRight: "40px" }}>
            <img src={feature1} width="auto" alt="" />
          </div>
          <div style={{ marginRight: "40px" }}>
            <img src={feature2} width="auto" alt="" />
          </div>
          <div style={{ marginRight: "40px" }}>
            <img src={feature3} width="auto" alt="" />
          </div>
          <div style={{ marginRight: "40px" }}>
            <img src={feature4} width="auto" alt="" />
          </div>
          <div style={{ marginRight: "40px" }}>
            <img src={feature5} width="auto" alt="" />
          </div>
          <div style={{ marginRight: "40px" }}>
            <img src={feature6} width="auto" alt="" />
          </div>
        </Marquee>
      </div>
      <div className="footer_mask"></div>
      <div className="footer_content">
        <div className="footer_title text-center">
          OF THE CRYPTO, BY THE CRYPTO, FOR THE CRYPTO!
        </div>
        <div className="footer_text text-center">
          Please be advised that Elumphant is a cryptocurrency created for
          entertainment purposes and is not affiliated with or endorsed by any
          celebrities or political party. While Elumphant may reference or
          incorporate elements related any individual’s persona, image, or
          likeness, it does not imply any direct endorsement, partnership, or
          approval by them. Any resemblance or association is purely
          coincidental and intended for satirical or humorous purposes
        </div>
        <div className="footer_social_btns">
          <div
            className="footer_social_btn"
            onClick={() => {
              window.open("https://x.com/elumphant", "_blank");
            }}
            id="socialBtn"
          >
            <RiTwitterXFill size={16} />
          </div>
          <div
            className="footer_social_btn"
            onClick={() => {
              window.open("https://t.me/elumphant_public", "_blank");
            }}
            id="TelegramBtn"
          >
            <FaTelegramPlane color={""} size={16} />
          </div>
        </div>
        <div
          className=""
          id="header_buy_btn"
          onClick={() => {
            history.replace("/#buySection"); // Update the URL hash
            setPreventAlertModal(true);

            // Add a small delay before scrolling
            setTimeout(() => {
              const section = document.getElementById("buySection");
              if (section) {
                section.scrollIntoView({ behavior: "smooth" });
              }
            }, 0); // Delay of 0ms ensures execution after URL update
          }}
        >
          <a className="buy_btn">Buy $Elump</a>
        </div>
        <div className="footer_btns">
          <div
            className="footer_btn"
            onClick={() => {
              history.push("/");
              setPreventAlertModal(true);
            }}
          >
            <a className="footer_btn">Home</a>
          </div>
          <div className="footer_btn">
            <Link className="footer_btn" to="/about">
              About
            </Link>
          </div>
          <div className="footer_btn">
            <Link className="footer_btn" to="/how-to-buy">
              How To Buy
            </Link>
          </div>
          <div className="footer_btn">
            <Link className="footer_btn" to="/whitepaper">
              WhitePaper
            </Link>
          </div>
          <div className="footer_btn">
            <Link className="footer_btn" to="/roadmap">
              RoadMap
            </Link>
          </div>
          <div className="footer_btn">
            <Link className="footer_btn" to="/staking">
              Staking
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
