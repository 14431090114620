import { useEffect, useState } from "react";
import { addRemoveClassBody } from "../../utils";
import axios from "axios";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { networks, projectId, metadata, ethersAdapter } from "../../config";
// import ActionButtonList from '../../components/ActionButtonList';
// import InfoList from '../../components/InfoList';
import { useModal } from "../../ContextProvider.js";

import SecHeroSection from "../../components/HeroSection";

// import WhitePaper from "../../components/WhitePaper/index.jsx";
import Modal from "../../components/Modal/";

const Main = () => {
  const apiKey = process.env.REACT_APP_ALCHEMY_API_KEY;
  const ethContractAddress = process.env.REACT_APP_ETH_CONTRACT;
  const bscContractAddress = process.env.REACT_APP_BSC_CONTRACT;
  const ethUsdtContractAddress = process.env.REACT_APP_ETH_USDT_CONTRACT;
  const bscUsdtContractAddress = process.env.REACT_APP_BSC_USDT_CONTRACT;
  const { isOpen, setIsOpen, setBuyAmount, buyAmount } = useModal();

  return (
    <div>
      <NotificationContainer />
      {/* <Header Title="Elumphant" isOpen={isOpen} setIsOpen={setIsOpen} /> */}
      <SecHeroSection
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        apiKey={apiKey}
        ethContractAddress={ethContractAddress}
        bscContractAddress={bscContractAddress}
        ethUsdtContractAddress={ethUsdtContractAddress}
        bscUsdtContractAddress={bscUsdtContractAddress}
        buyAmount={buyAmount}
      />
      {/* <HowToStart data={HowToStartInfo} setIsOpen={setIsOpen} isOpen={isOpen} /> */}

      {/* <Footer /> */}
    </div>
  );
};

export default Main;
