import {
  ExclusiveNFTAirdropImg,
  FullyDecentralizedGovernanceImg,
  HighAPYStakingImg,
  MemePowerImg,
  MultiChainImg,
  NoRugPull,
  SeamlessImg,
  SmartContractImg,
} from "../../utils/allImgs";

export const aboutCardData = [
  {
    title: "Meme Power",
    content:
      "Elumphant blends Elon Musk's ambition, Trump's boldness, and the iconic Republican elephant. The story only grows, and his powerful mix of charisma, humor, and cultural relevance makes it a standout in the meme-verse.",
    index: 1,
    img: MemePowerImg,
  },
  {
    title: "Exclusive NFT Airdrop",
    content:
      "Elumphant will airdrop 400 epic rarity NFTs to the top 200 rich list holders and 200 early buyers. These NFTs are a badge of honor for early supporters. And the NFT sale starts right after the IDO.",
    index: 2,
    img: ExclusiveNFTAirdropImg,
  },
  {
    title: "No Rug Pull",
    content:
      "Community Driven: Elumphant’s hard-coded lock ensures safety and trust. The project is fully community-driven—putting power and control in the hands of the holders.",
    index: 3,
    img: NoRugPull,
  },
  {
    title: "Highly Secure Smart Contract",
    content:
      "Developed by experts from top DeFi brands, our smart contract is highly secure and built with the highest standards to protect investor interests.",
    index: 5,
    img: SmartContractImg,
  },
  {
    title: "High APY Staking",
    content:
      "Stake your $Elump tokens to earn high APY and maximize gains. The more you stake, the more you earn—helping you grow along with the community.",
    index: 7,
    img: HighAPYStakingImg,
  },
  {
    title: "Fully Decentralized Governance",
    content:
      "Unlike other meme tokens, Elumphant has minimal owner functions, ensuring true decentralization and giving control to the community.",
    index: 8,
    img: FullyDecentralizedGovernanceImg,
  },
];
