import React from "react";
import WhitePaperComponent from "../../components/WhitePaperComponent";
const WhitePaper = () => {
  return (
    <div>
      <WhitePaperComponent />
    </div>
  );
};

export default WhitePaper;
