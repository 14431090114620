import React, { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [buyAmount, setBuyAmount] = useState(0);
  const [isConnectedStatus, setIsConnectedStatus] = useState(false);
  const [totalPurchasedAmount, setTotalPurchasedAmount] = useState(0);
  const [preventAlertModal, setPreventAlertModal] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        setIsOpen,
        buyAmount,
        setBuyAmount,
        isConnectedStatus,
        setIsConnectedStatus,
        totalPurchasedAmount,
        setTotalPurchasedAmount,
        preventAlertModal,
        setPreventAlertModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
