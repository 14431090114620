import { useEffect, useState, useCallback } from "react";
import { addRemoveClassBody } from "../../utils";
import { ethers } from "ethers";
import { LuCopy } from "react-icons/lu";

import ElumphantStakingABI from "../../abi/ElumphantStaking.json";
import ElumphantPresaleABI from "../../abi/ElumphantPresale.json";
import { useModal } from "../../ContextProvider";
import { TbStarFilled } from "react-icons/tb";
import { GoTriangleRight } from "react-icons/go";
import Modal from "../../components/Modal";
import BuyModal from "../../components/BuyModal";
import {
  useAppKitAccount,
  useAppKitNetwork,
  useAppKit,
  useAppKitProvider,
} from "@reown/appkit/react";
import { networks, projectId, metadata, ethersAdapter } from "../../config";

import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import Footer from "../../layouts/Footer";
import "./Stacking.scss";
import { useHistory } from "react-router-dom";
import { FistImg, NoseFistImg } from "../../utils/allImgs";
import StakingModal from "../../components/StakingModal";
import { createAppKit } from "@reown/appkit/react";

const StackingPage = () => {
  // createAppKit({
  //   adapters: [ethersAdapter],
  //   networks,
  //   metadata,
  //   projectId,
  //   features: {
  //     analytics: true, // Optional - defaults to your Cloud configuration
  //     socials: false,
  //     email: false,
  //     emailShowWallets: true,
  //   },
  //   // allWallets: 'HIDE',
  // });

  const history = useHistory();

  const { open, close } = useAppKit();
  const { address, caipAddress, isConnected, status } = useAppKitAccount();
  // const { walletInfo } = useWalletInfo();
  const { caipNetwork, caipNetworkId, chainId, switchNetwork } =
    useAppKitNetwork();

  const { walletProvider } = useAppKitProvider("eip155");

  const {
    isConnectedStatus,
    totalPurchasedAmount,
    preventAlertModal,
    setPreventAlertModal,
  } = useModal();
  const apiKey = process.env.REACT_APP_ALCHEMY_API_KEY;

  const ethContractAddress = process.env.REACT_APP_ETH_CONTRACT;
  const bscContractAddress = process.env.REACT_APP_BSC_CONTRACT;
  // const ethUsdtContractAddress = process.env.REACT_APP_ETH_USDT_CONTRACT;
  // const bscUsdtContractAddress = process.env.REACT_APP_BSC_USDT_CONTRACT;
  const ethStakingContractAddress = process.env.REACT_APP_STAKING_ETH_CONTRACT;
  const bscStakingContractAddress = process.env.REACT_APP_STAKING_BSC_CONTRACT;

  const decimals = 18n;
  const divisor = 10n ** decimals; // 10^18

  const [copiedAddress, setCopiedAddress] = useState(false);

  const [lockedTimeType, setLockedTimeType] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [stakingIsOpen, setStakingIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [withdrawLockedPeriod, setWithdrawLockedPeriod] = useState("");
  const [claimLockedPeriod, setClaimLockedPeriod] = useState("");
  const [stakingAmountToParsedWei, setStakingAmountToParsedWei] = useState("");
  const [stakingStatus, setStakingStatus] = useState(false);
  // Live
  const RPC_URL_ETH = `https://eth-mainnet.g.alchemy.com/v2/${apiKey}`;
  const RPC_URL_BSC = `https://bnb-mainnet.g.alchemy.com/v2/${apiKey}`;
  // Test
  // const RPC_URL_ETH = `https://eth-sepolia.g.alchemy.com/v2/${apiKey}`;
  // const RPC_URL_BSC = `https://bnb-testnet.g.alchemy.com/v2/${apiKey}`;
  // States
  const [stakedBalance, setStakedBalance] = useState(0);
  const [stakableAmount, setStakableAmount] = useState(0);
  const [stakableETHAmount, setStakableETHAmount] = useState("");
  const [stakableBSCAmount, setStakableBSCAmount] = useState("");

  const [percentageOfPoll, setPercentageOfPoll] = useState(0);
  const [totalStaked, setTotalStaked] = useState(0);
  const [estimatedRewards, setEstimatedRewards] = useState(0);
  const [currentRewards, setCurrentRewards] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [selectedNetwork, setSelectedNetwork] = useState(1);

  const [stakingAmount, setStakingAmount] = useState(""); // Initialize with an empty string
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState("");

  const [stakingSuccess, setStakingSuccess] = useState(false);
  const [switchETHLoading, setSwitchETHLoading] = useState(false);
  const [switchBSCLoading, setSwitchBSCLoading] = useState(false);
  const [stakingError, setStakingError] = useState(false);
  const [stakingRealAmount, setStakingRealAmount] = useState(0);

  // const ethProvider = new ethers.JsonRpcProvider(RPC_URL_ETH);
  // const bscProvider = new ethers.JsonRpcProvider(RPC_URL_BSC);

  // Validation
  useEffect(() => {
    if (stakingIsOpen === false) {
      return;
    } else {
      console.log(">>>>>>>", stakingAmount);
      if (selectedNetwork === 1 && stakingAmount > stakableETHAmount) {
        setValidation(`Max: ${stakableETHAmount}`);
      } else if (selectedNetwork === 1 && stakingAmount < stakableETHAmount) {
        setValidation("");
      } else if (selectedNetwork === 2 && stakingAmount > stakableBSCAmount) {
        setValidation(`Max: ${stakableBSCAmount}`);
      } else if (selectedNetwork === 2 && stakingAmount < stakableBSCAmount) {
        setValidation("");
      }
    }
  }, [stakingAmount]);
  // minimizeAddress function
  function minimizeAddress(addr) {
    return addr.substring(0, 6) + "..." + addr.substring(addr.length - 4);
  }
  // formatDateToMMMDDYYYY function
  function formatDateToMMMDDYYYY(date) {
    return date
      .toLocaleDateString("en-US", {
        month: "short", // Abbreviated month name
        day: "2-digit", // Two-digit day
        year: "numeric", // Full year
      })
      .replace(",", ""); // Remove comma for desired format
  }
  function convertBigIntTimestampToDate(timestampInSeconds) {
    // Convert BigInt seconds to milliseconds
    const timestampInMilliseconds = timestampInSeconds * 1000n;
    // Create and return the Date object
    const convertedDate = new Date(Number(timestampInMilliseconds));
    const date = formatDateToMMMDDYYYY(convertedDate);

    const localDate = convertedDate.toLocaleString();
    const UTCDate = convertedDate.toISOString();
    return date;
  }
  // @ Get Purchased Amount
  const getStakedBalancedHandler = async () => {
    const ethProvider = new ethers.JsonRpcProvider(RPC_URL_ETH);
    const bscProvider = new ethers.JsonRpcProvider(RPC_URL_BSC);

    const ethContract = new ethers.Contract(
      ethContractAddress,
      ElumphantPresaleABI.abi,
      ethProvider
    );
    const bscContract = new ethers.Contract(
      bscContractAddress,
      ElumphantPresaleABI.abi,
      bscProvider
    );
    const ethStakingContract = new ethers.Contract(
      ethStakingContractAddress,
      ElumphantStakingABI.abi,
      ethProvider
    );
    const bscStakingContract = new ethers.Contract(
      bscStakingContractAddress,
      ElumphantStakingABI.abi,
      bscProvider
    );

    try {
      let totalStakedNumber = 0;
      let totalPurchased = 0;
      const commonETHLockedTime = await ethStakingContract.lockedTime();

      if (address === undefined) {
        totalPurchased = 0;
        setStakedBalance(0);
        setStakableAmount(0);
        setStakableETHAmount(0);
        setStakableBSCAmount(0);
      } else {
        const purchasedOnETH = await ethContract.purchasedTokens(address);
        const purchasedOnBSC = await bscContract.purchasedTokens(address);
        totalPurchased = Number((purchasedOnETH + purchasedOnBSC) / divisor);
        // Get My Total Staked Amount
        const stakedETH = await ethContract.stakedTokens(address);
        const stakedBSC = await bscContract.stakedTokens(address);
        const myTotalStaked = stakedETH + stakedBSC;
        totalStakedNumber = Number(myTotalStaked / divisor);
        setStakedBalance(totalStakedNumber);
        setStakableAmount(totalPurchased - totalStakedNumber);
        setStakableETHAmount(
          Number(purchasedOnETH / divisor) - Number(stakedETH / divisor)
        );
        setStakableBSCAmount(
          Number(purchasedOnBSC / divisor) - Number(stakedBSC / divisor)
        );

        //Get Lock Time
        const withdrawETHPoolTime = await ethStakingContract.poolStakers(
          address
        );
        const claimETHTime = await ethStakingContract.claimStart();
        const maxETHTime =
          withdrawETHPoolTime[1] > claimETHTime
            ? withdrawETHPoolTime[1]
            : claimETHTime;
        //
        const withdrawBSCPoolTime = await bscStakingContract.poolStakers(
          address
        );
        const claimBSCTime = await bscStakingContract.claimStart();
        const maxBSCTime =
          withdrawBSCPoolTime[1] > claimBSCTime
            ? withdrawBSCPoolTime[1]
            : claimBSCTime;
        const maxLockedTime = maxETHTime > maxBSCTime ? maxETHTime : maxBSCTime;
        const lockedTimeValue = maxLockedTime + commonETHLockedTime;
        const date = await convertBigIntTimestampToDate(lockedTimeValue);
        const timestamp = Math.floor(Date.now() / 1000);
        const leftTime = Number(lockedTimeValue) - timestamp;
        if (leftTime > 0) {
          const hours = Math.floor(leftTime / 3600); // Divide by 3600 to get the full hours
          setClaimLockedPeriod(hours);
        } else {
          setClaimLockedPeriod(0);
        }
        setWithdrawLockedPeriod(date);
      }

      // Get All Total Staked Amount
      const totalETHStaked = await ethStakingContract.tokensStaked();
      const totalBSCStaked = await bscStakingContract.tokensStaked();
      const totalStakedAmount = Number(
        (totalETHStaked + totalBSCStaked) / divisor
      );
      setTotalStaked(totalStakedAmount);
      setPercentageOfPoll(
        totalStakedNumber === 0 ? 0 : totalStakedNumber / totalStakedAmount
      );
      // Get Per Block Rewards - Current Rewards
      const ETHRewardsPerBlock =
        await ethStakingContract.rewardTokensPerBlock();
      // const BSCRewardsPerBlock =
      //   await bscStakingContract.rewardTokensPerBlock();
      // const totalRewardsPerBlock = Number(
      //   (ETHRewardsPerBlock + BSCRewardsPerBlock) / divisor
      // );
      setCurrentRewards(
        parseFloat(Number(ETHRewardsPerBlock) / Number(divisor)).toFixed(3)
      );

      // Get Estimated Rewards

      setEstimatedRewards(
        ((Number(ETHRewardsPerBlock) / Number(divisor)) * 300 * 365 * 24) /
          totalStakedAmount
      );
      // Get Total Rewards
      if (address !== undefined) {
        const totalETHRewards = await ethStakingContract.getRewards(address);
        const totalBSCRewards = await bscStakingContract.getRewards(address);
        const totalRewardsAmount = Number(
          (totalETHRewards + totalBSCRewards) / divisor
        );
        setTotalRewards(totalRewardsAmount);
      } else {
        setTotalRewards(0);
      }
    } catch (error) {
      console.log("getStakedBalancedHandler Error: ", error);
    }
  };
  useEffect(() => {
    getStakedBalancedHandler();
  }, [isConnected, stakingStatus]);
  //

  const [stars, setStars] = useState([]); // State to store generated stars

  useEffect(() => {
    // Generate stars only once when the component mounts
    const generateRandomStars = (numStars) => {
      const starsArray = [];
      for (let i = 0; i < numStars; i++) {
        const randomTop = Math.random() * 100; // Random percentage for top
        const randomLeft = Math.random() * 100; // Random percentage for left
        const randomSize = Math.random() * 1.5 + 0.5; // Random size multiplier

        starsArray.push(
          <TbStarFilled
            key={i}
            style={{
              position: "absolute",
              top: `${randomTop}%`,
              left: `${randomLeft}%`,
              transform: `scale(${randomSize})`,
              color: "white",
            }}
          />
        );
      }
      return starsArray;
    };

    setStars(generateRandomStars(18)); // Generate 18 stars
  }, []); // Empty dependency array ensures this runs only once
  const formatNumber = (number) => {
    const [integerPart, decimalPart] = number.toString().split(".");
    const formattedInteger = parseInt(integerPart, 10).toLocaleString(); // Format the integer part with commas
    return decimalPart
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const withdrawHandler = () => {
    if (stakedBalance === 0) {
      return;
    }
    setLockedTimeType("withdraw");
    setIsOpen(true);
    setMessage(`Your staked Balance is locked until ${withdrawLockedPeriod}`);
  };
  const claimRewardsHandler = () => {
    if (totalRewards === 0) {
      return;
    }
    setLockedTimeType("claim");
    setIsOpen(true);
    setMessage(`Unable to claim rewards during the lock time`);
  };
  const copyAddressHandler = async () => {
    console.log("Address >>>>", address);

    if (!navigator.clipboard || !navigator.clipboard.writeText) {
      console.warn("Clipboard API not supported. Using fallback.");
      // Fallback: Use a hidden textarea to copy the address
      const textArea = document.createElement("textarea");
      textArea.value = address;
      textArea.style.position = "fixed"; // Prevent scrolling
      textArea.style.opacity = "0"; // Make invisible
      document.body.appendChild(textArea);
      textArea.select();
      try {
        const success = document.execCommand("copy"); // Use fallback method
        if (success) {
          setCopiedAddress(true); // Set copied state to true on success
        } else {
          setCopiedAddress(false);
          console.log("Fallback copy failed. Please copy manually.");
        }
      } catch (err) {
        console.error("Fallback copy failed: ", err);
        setCopiedAddress(false);
      }
      document.body.removeChild(textArea);
      return;
    }

    // Clipboard API
    try {
      await navigator.clipboard.writeText(address);
      setCopiedAddress(true);
      setTimeout(() => setCopiedAddress(false), 1000);
      console.log("Address copied successfully!");
    } catch (err) {
      console.error("Failed to copy address: ", err);
    }
  };

  const openConnectWalletModalHandler = async () => {
    await open();
  };
  console.log("Test >>>", chainId);

  const stakingHandler = async () => {
    if (stakableAmount === 0) {
      return;
    }
    setSwitchETHLoading(true);

    try {
      if (chainId === 1) {
        await switchNetwork(networks[0]);
        setSwitchETHLoading(false);
        setStakingIsOpen(true);
      } else {
        setSwitchETHLoading(false);
        setStakingIsOpen(true);
      }
    } catch (error) {
      setSwitchETHLoading(false);
      return;
    }
  };
  const closeStakingModalHandler = async () => {
    await switchNetwork(networks[0]);
    setSwitchETHLoading(false);
    setStakingIsOpen(false);
  };
  const onStakingHandler = async () => {
    if (
      stakingAmount.trim() === "" ||
      isNaN(Number(stakingAmount)) ||
      Number(stakingAmount) <= 1
    ) {
      setValidation("Invalid Amount");
      return;
    } else if (validation !== "" || loading === true) {
      return;
    } else {
      setStakingError(false);
      setLoading(true);
      try {
        const provider = new ethers.BrowserProvider(walletProvider);
        const signer = await provider.getSigner();

        const ethContract = new ethers.Contract(
          ethContractAddress,
          ElumphantPresaleABI.abi,
          signer
        );
        const bscContract = new ethers.Contract(
          bscContractAddress,
          ElumphantPresaleABI.abi,
          signer
        );

        const toStringStakingAmount = stakingAmountToParsedWei.toString();
        try {
          if (selectedNetwork === 1) {
            const transaction = await ethContract.claimAndStake(
              toStringStakingAmount
            );
            const status = await transaction.wait();
            console.log("ETH Transaction Successful: ", status);
            setStakingAmount("");
            setStakingStatus(!stakingStatus);
            setLoading(false);
            setStakingSuccess(true);
            setStakingIsOpen(false);
          } else {
            const transaction = await bscContract.claimAndStake(
              toStringStakingAmount
            );
            const status = await transaction.wait();
            console.log("BSC Transaction Successful: ", status);
            setStakingAmount("");
            setStakingStatus(!stakingStatus);
            setLoading(false);
            setStakingSuccess(true);
            setStakingIsOpen(false);
          }
        } catch (error) {
          console.log("onStakingHandler with network : ", error);
          setStakingSuccess(false);
          setStakingError(true);
        }
        setLoading(false);
      } catch (error) {
        console.log("onStakingHandler Error >>>", error);
        setStakingSuccess(false);
        setStakingAmount("");
        setLoading(false);
        setStakingError(true);
      }
    }
  };
  const onChangeNetworkHandler = async () => {};

  const setNetworkETHHandler = async () => {
    if (loading === true) return;

    setStakingError(false);
    setSwitchETHLoading(true);

    try {
      // Initiate the network switch
      const res = await switchNetwork(networks[0]);
      console.log("Network switch initiated: ", res);

      // Polling to check if the chain ID is updated
      const maxRetries = 10; // Maximum attempts to check
      const interval = 300; // Time in milliseconds between checks

      let retries = 0;

      while (retries < maxRetries) {
        if (chainId === 1) {
          console.log("Chain ID switched to 1");
          setSwitchETHLoading(false);
          return; // Exit the function as the chain ID matches
        }

        console.log("Waiting for chain ID to switch...");
        await new Promise((resolve) => setTimeout(resolve, interval)); // Wait for the interval
        retries++;
      }

      // If max retries are reached
      console.warn("Chain ID did not switch within the timeout.");
      setSwitchETHLoading(false);
    } catch (error) {
      console.error("ETH Error: ", error);
      setSwitchETHLoading(false);
    }
  };

  const setNetworkBSCHandler = async () => {
    if (loading === true) return;
    setStakingError(false);
    setSwitchBSCLoading(true);

    try {
      // Initiate the network switch
      const res = await switchNetwork(networks[1]);
      console.log("Network switch initiated: ", res);

      // Polling to check if the chain ID is updated
      const maxRetries = 10; // Maximum attempts to check
      const interval = 300; // Time in milliseconds between checks

      let retries = 0;

      while (retries < maxRetries) {
        if (chainId === 56) {
          console.log("Chain ID switched to BSC (97)");
          setSwitchBSCLoading(false);
          return; // Exit the function as the chain ID matches
        }

        console.log("Waiting for BSC chain ID to switch...");
        await new Promise((resolve) => setTimeout(resolve, interval)); // Wait for the interval
        retries++;
      }

      // If max retries are reached
      console.warn("Chain ID did not switch to BSC (97) within the timeout.");
      setSwitchBSCLoading(false);
    } catch (error) {
      console.error("BSC Error: ", error);
      setSwitchBSCLoading(false);
    }
  };

  useEffect(() => {
    if (chainId === 1) {
      setSelectedNetwork(1);
    } else if (chainId === 56) {
      setSelectedNetwork(2);
    } else {
      switchNetwork(networks[0]);
    }
  }, [chainId]);
  // @@@DOM@@@
  return (
    <div className="" id="stacking">
      <StakingModal
        isOpen={stakingIsOpen}
        onClose={closeStakingModalHandler}
        setStakingRealAmount={setStakingRealAmount}
        switchETHLoading={switchETHLoading}
        switchBSCLoading={switchBSCLoading}
        setStakingAmount={setStakingAmount}
        stakingAmount={stakingAmount}
        stakableETHAmount={stakableETHAmount}
        stakableBSCAmount={stakableBSCAmount}
        setLoading={setLoading}
        loading={loading}
        onStakingHandler={onStakingHandler}
        setValidation={setValidation}
        validation={validation}
        stakableAmount={stakableAmount}
        setStakingAmountToParsedWei={setStakingAmountToParsedWei}
        setSelectedNetwork={setSelectedNetwork}
        selectedNetwork={selectedNetwork}
        setStakingStatus={setStakingStatus}
        onChangeNetworkHandler={onChangeNetworkHandler}
        formatNumber={formatNumber}
        setNetworkETHHandler={setNetworkETHHandler}
        setNetworkBSCHandler={setNetworkBSCHandler}
        stakingSuccess={stakingSuccess}
        setStakingError={setStakingError}
        stakingError={stakingError}
      />
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        message={message}
        lockedTimeType={lockedTimeType}
        withdrawLockedPeriod={withdrawLockedPeriod}
        claimLockedPeriod={claimLockedPeriod}
      />

      <BuyModal
        isOpen={stakingSuccess}
        stakingRealAmount={stakingRealAmount}
        type="staking"
        onClose={() => {
          setStakingSuccess(false);
          setStakingIsOpen(false);
        }}
      />

      {/* <div className="stacking_mask" /> */}
      <div className="section staking_image">
        <a href="#stakingSection" className="scroll-downs">
          <div className="mousey">
            <div className="scroller"></div>
          </div>
        </a>
      </div>
      <div
        className="arrow_left_btn"
        onClick={() => {
          history.push("/roadmap");
        }}
      >
        <IoIosArrowDropleftCircle size={50} color="gold" />
      </div>
      <div
        className="arrow_right_btn"
        onClick={() => {
          history.push("/");
          setPreventAlertModal(true);
        }}
      >
        <IoIosArrowDroprightCircle size={50} color="gold" />
      </div>
      <div
        id="stakingSection"
        className="section stacking_content"
        style={{ width: "100%" }}
      >
        <div>{stars}</div>
        <div className="fist_images">
          <img src={NoseFistImg} width={250} height={250} alt="" />
          <img src={FistImg} width={250} height={250} alt="" />
        </div>
        <div className="connect_wallet_btn">
          {isConnected === true ? (
            <div>
              Connected Wallet:{" "}
              <span onClick={copyAddressHandler}>
                {minimizeAddress(address)}
              </span>{" "}
              <span onClick={copyAddressHandler} className="copy_icon">
                <LuCopy color={copiedAddress ? "white" : "black"} />
              </span>
              ( {caipNetwork?.name} )
            </div>
          ) : (
            <div onClick={openConnectWalletModalHandler}>
              Connect your wallet to stake $Elump
            </div>
          )}
        </div>
        <div className="stacking-items">
          <div className="stacking-items__item">
            <div>
              <div className="funny-font" style={{ color: "white" }}>
                Staked Balance
              </div>
              <div className="funny-font" style={{ color: "gold" }}>
                {formatNumber(stakedBalance)} $Elump
              </div>
              <div className="funny-font" style={{ color: "white" }}>
                Your stakable
              </div>

              <div className="funny-font" style={{ color: "gold" }}>
                {formatNumber(stakableAmount)} $Elump
              </div>
            </div>
            <div
              className={
                stakableAmount === 0 ? "btn btn-disabled" : "btn btn-warning"
              }
              style={{ fontSize: "14px", fontWeight: "bold" }}
              onClick={stakingHandler}
            >
              Stake
            </div>
            <div
              className="btn btn-warning"
              style={{ fontSize: "14px", fontWeight: "bold" }}
              onClick={() => {
                history.replace("/#buySection"); // Update the URL hash
                setPreventAlertModal(true);

                // Add a small delay before scrolling
                setTimeout(() => {
                  const section = document.getElementById("buySection");
                  if (section) {
                    section.scrollIntoView({ behavior: "smooth" });
                  }
                }, 0); // Delay of 0ms ensures execution after URL update
              }}
            >
              Buy $Elump
            </div>
          </div>

          <div className="stacking-items__item">
            <div>
              <div className="funny-font" style={{ color: "white" }}>
                % of Pool
              </div>
              <div className="funny-font" style={{ color: "gold" }}>
                {totalStaked === 0 ? 0 : (percentageOfPoll * 100).toFixed(3)}%
              </div>
            </div>
            <div>
              <div className="funny-font" style={{ color: "white" }}>
                Total Staked
              </div>
              <div className="funny-font" style={{ color: "gold" }}>
                {formatNumber(totalStaked)} $Elump
              </div>
            </div>
            <div
              style={{ fontSize: "14px", fontWeight: "bold" }}
              className={
                stakedBalance === 0 ? "btn btn-disabled" : "btn btn-warning"
              }
              onClick={withdrawHandler}
            >
              Withdraw Staked Tokens
            </div>
          </div>

          <div className="stacking-items__item">
            <div>
              <div className="funny-font" style={{ color: "white" }}>
                Estimated Rewards
              </div>
              <div style={{ display: "flex", alignItems: "start" }}>
                <div className="funny-font" style={{ color: "gold" }}>
                  {formatNumber((estimatedRewards * 100).toFixed(2))}%
                </div>
                <div
                  className="funny-font"
                  style={{
                    color: "gold",
                    fontSize: "10px",
                    paddingLeft: "2px",
                  }}
                >
                  P/A
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div style={{ marginTop: "2px" }}>
                    <GoTriangleRight color="white" />
                  </div>
                  <div style={{ fontSize: "12px", color: "white" }}>
                    Rewards rate is dynamic
                  </div>
                </div>
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div style={{ marginTop: "2px" }}>
                    <GoTriangleRight color="white" />
                  </div>
                  <div style={{ fontSize: "12px", color: "white" }}>
                    Monthly = Rewards % / 12
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div style={{ marginTop: "2px" }}>
                    <GoTriangleRight color="white" />
                  </div>
                  <div style={{ fontSize: "12px", color: "white" }}>
                    Daily = Rewards % / 365
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="funny-font" style={{ color: "white" }}>
                Lock Time
              </div>
              <div className="funny-font" style={{ color: "gold" }}>
                1 year
              </div>
            </div>
          </div>
          {/* <div className="stacking-items__item">
            <div>
              <div className="funny-font" style={{ color: "white" }}>
                Current Rewards
              </div>
              <div className="funny-font" style={{ color: "gold" }}>
                {currentRewards}{" "}
                <span style={{ fontSize: "10px" }}>Per ETH Block</span>
              </div>
            </div>
          </div> */}
          <div className="stacking-items__item">
            <div>
              <div className="funny-font" style={{ color: "white" }}>
                Current Rewards
              </div>
              <div className="funny-font" style={{ color: "gold" }}>
                {formatNumber(currentRewards)}{" "}
                <span style={{ fontSize: "10px" }}>Per ETH Block</span>
              </div>
            </div>
            <div>
              <div className="funny-font" style={{ color: "white" }}>
                Total Rewards
              </div>
              <div className="funny-font" style={{ color: "gold" }}>
                {formatNumber(totalRewards)} $Elump
              </div>
            </div>
            <div
              className={
                totalRewards === 0 ? "btn btn-disabled" : "btn btn-warning"
              }
              style={{ fontSize: "14px", fontWeight: "bold" }}
              onClick={claimRewardsHandler}
            >
              Claim Rewards
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <Footer />
      </div>
    </div>
  );
};

export default StackingPage;
