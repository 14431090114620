import React, { useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { CongratulationGif } from "../../utils/allImgs";
import "./BuyModal.css";
function BuyModal({
  onClose,
  isOpen,
  type,
  onConnectMetamask,
  onConnectBestwallet,
  onConnectWalletConnect,
  stakingRealAmount,
  stakingAmount,
}) {
  const modalRef = useRef(null);

  if (!isOpen) return null;

  const handleOverlayClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };
  return (
    <div className="overlay fade show" onClick={handleOverlayClick}>
      <div className="buy_modal_body" ref={modalRef}>
        <div className="buy_modal_mask"></div>
        <div onClick={onClose} className="buy_modal_container">
          <div className="buy_modal_close_icon">
            <IoMdClose color="white" size={30} fontWeight={"bold"} />
          </div>
        </div>
        <div className="buy_modal_content">
          <h5 className="buy_modal_title">
            Congratulations, Elumphant Pioneer! 🎉
          </h5>
          <img src={CongratulationGif} width="30%" height="30%" alt="" />
          {type === "BuyAndStaking" ? (
            <div className="buy_modal_text">
              You have bought and staked {stakingAmount} $Elump
            </div>
          ) : (
            <div className="buy_modal_text">
              You have bought {stakingAmount} $Elump
            </div>
          )}
          {type === "staking" && (
            <div className="buy_modal_text">
              You have staked {stakingRealAmount} $Elump
            </div>
          )}

          <div className="buy_modal_text">
            Welcome to the herd—let's make history,
            <br /> one trumpet blast at a time! 🚀🐘✨
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyModal;
